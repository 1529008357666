export const environment = {
  production: false,
  baseUrl: 'https://geoappbcksvc-qa.azurewebsites.net/api/',
  logoutUrl: '',
  labTechGroupId: 'e4097555-0942-4fa9-8b7d-d5ab8481f59a',
  labManagerGroupId: 'be33ffe4-28c8-48bf-a3bd-a03386eb99dd',
  reportWriterGroupId: '7572a879-972f-49fe-ba8c-4ab24e896153',
  adminGroupId: '49ff3c55-6a6a-4da4-8400-be42fefafd6a',
  appInsights: {
    instrumentationKey: '29050fc9-6af0-451d-a49e-444b46f24440',
  },
  contactEmail: 'support@geothermusa.com',
};

export const AzureAdB2C = {
  clientId: '6b1db31b-1359-4b14-b32c-d5557c31dcb3',
  redirectUri: 'https://geoapp-qa.geothermusa.com/',
  authorty: 'https://login.microsoftonline.com/7618b1f7-7cda-4be7-9f85-f7c80a1e0c8b',
  scope: ['user.read'],
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/', ['user.read']],
    [`${environment.baseUrl}lookup/getprojectstatuses`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}lookup/getsignaturetypes`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}lookup/getsamplestatuses`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}dashboard`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}reports`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}precastws`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}nativesoilws`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}company`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}project`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}note`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}notification`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}map`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}storage`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}sample`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}admin`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}user`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}ml`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}auditlog`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}auditlog/getbloburl`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}lookup/getalluseremails`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}lookup/getauditresourcetypes`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}lookup/getauditactiontypes`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
    [`${environment.baseUrl}splitmerge`, ['api://49af5275-f11b-4383-90ca-39c6d0ffc27c/GeoAPPQA.read']],
  ]),
  cacheLocation: 'localStorage',
};

export const googleCaptcha = {
  siteKey: '6LeUjFcoAAAAAC4zKcmbLXAg3eXAOw4VYSorcgsW',
};

export const geoXplore = {
  accessToken: 'pk.eyJ1IjoidXBlbm5kZXJyYW5lcnUiLCJhIjoiY2xsYjJjenpoMDJ2NzNmcXQxazdvZnFjNiJ9.uK17wUrxnk0SVmSKR3os-Q',
};
