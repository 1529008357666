import { Injectable } from '@angular/core';
import { AlignmentType,
  Footer,
  Header,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  Document,
  VerticalAlign,
  WidthType,
  ShadingType,
  ExternalHyperlink,
  AlignmentAttributes, 
  HeightRule,
  SectionType,
  PageBreak,
  PageNumber,
  NumberFormat} from 'docx';
import * as saveAs from 'file-saver';
import { single, Subject } from 'rxjs';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';

@Injectable({
  providedIn: 'root',
})
export class WordReportUtils {
  previewData: REPORTPREVIEW;
  soilType: string;
  title = '';
  signature: ISIGNATURE;
  logo: string;
  address: IADDRESS;
  figureImages: any[] = [];
  imageCount = 0;
  forPdfTableDta: any[] = [];
  formattedDate: string;

  private destroyed$ = new Subject<boolean>();
  public commonForWordData(
    previewValue: REPORTPREVIEW,
    soilType: string,
    title: string,
    sign: ISIGNATURE,
    logo: string,
    address: IADDRESS,
    figureImages: any,
    imageCount: number,
    forPdfTableDta: any,
  ) {
    this.previewData = previewValue;
    this.soilType = soilType;
    this.title = title;
    this.signature = sign;
    this.logo = logo;
    this.address = address;
    this.figureImages = figureImages;
    this.imageCount = imageCount;
    this.forPdfTableDta = forPdfTableDta;
  }

  public commonStyle = {
    font: 'Arial',
    size: 22, // Size in half-points (22 means 11pt)
    color: '000000', // Hex color without #
    tableFontsize: 18,
    tableFont: 'Arial',
  };

  public generateWord() {
    this.formattedDate = this.previewData.reportDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long', // Long month format
      day: 'numeric',
    });
    const letterFooter = new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          text: 'COOL SOLUTIONS FOR UNDERGROUND POWER CABLES',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Serving the electric power industry since 1978',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const blankCell = new Paragraph({
      children: [
        new TextRun({
          text: '',
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterHeaderAddress = new Paragraph({
      children: [
        new TextRun({
          text: `${this.address.street1} , ${this.address.street2}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `${this.address.city}, ${this.address.state}${this.address.zipcode} `,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `Tel: ${this.address.ph}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.formatEmailList(this.address.email!),
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.address.weburl,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterAddress = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: this.formattedDate,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.companyName,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.streetAddress,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `${`${this.previewData.city}, ${this.previewData.state} ${this.previewData.postalCode}`}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `Attn: ${this.previewData.contactName}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {
          },
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterHeading = new Paragraph({
      children: [
        new TextRun({
          text: this.previewData.header,
          bold: true,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          color: '#000000',
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.subHeader,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          color: '#000000',
          underline: {
          },
        }),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
      ],
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.CENTER,
    });
    const letterSignature = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Geotherm USA, LLC,',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new ImageRun({
          data: this.signature.signatureImage,
          transformation: {
            width: 140,
            height: 45,
          },
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: this.signature.label,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
    });
    const pageHeader = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: this.logo,
                      transformation: {
                        width: 300,//250//200//150
                        height: 78,//65//52//39
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 4535,
                type: WidthType.DXA,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
            new TableCell({
              children: [blankCell],
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
            new TableCell({
              children: [letterHeaderAddress],
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
          ],
        }),
      ],
    });
    const restPageHeader = new Paragraph({
      pageBreakBefore: true,
      children: [
        new ImageRun({
          data: this.logo,
          transformation: {
            width: 200,
            height: 52,
          },
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
      ],
      alignment: 'left',
    });
    const restPageFooter = new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
              children: [PageNumber.CURRENT],
          }),
      ],
  });
   // ----------------For Main body------------------
   const parserForMainBody = new DOMParser();
   const htmlMainBodyDoc = parserForMainBody.parseFromString(this.previewData.mainBody!, 'text/html');
   const bodyhtmlMainBodyDoc = htmlMainBodyDoc.body.children;
   const htmlMainBody = this.parseHtmlToDocx(bodyhtmlMainBodyDoc);
    // ----------------For Geotherm Comment------------------
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(this.previewData.geothermComment!, 'text/html');
    const bodyChildren = htmlDoc.body.children;
    const htmlBodyGeothermComment = this.parseHtmlToDocx(bodyChildren);
    // ----------------For Recommendation------------------
    const parserForRecom = new DOMParser();
    const htmltoDoc = parserForRecom.parseFromString(this.previewData.recommendations!, 'text/html');
    const bodyChild = htmltoDoc.body.children;
    const htmlBodyRecommendation = this.parseHtmlToDocx(bodyChild);
    //----------------------------------------------------------
    const doc: any = new Document({
      numbering: {
        config: [
          {
            reference: 'ordered-list',
            levels: [
              {
                level: 0,
                format: 'decimal',
                text: '%1.',
                alignment: AlignmentType.LEFT,
              },
            ],
          },
        ],
      },
      styles: {
        paragraphStyles: [
          {
            id: 'DefaultParagraph',
            name: 'Default Paragraph',
            basedOn: 'Normal',
            run: {
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            },
          },
        ],
      },
      sections: [
        {
          properties: {
            type: SectionType.NEXT_PAGE,
            titlePage: true,
            page: {
              pageNumbers: {
                  start: 1,
                  formatType: NumberFormat.DECIMAL,
              },
          },
          },
          headers: {
            default: new Header({
              children: [restPageHeader],
            }),
            first: new Header({
              children: [pageHeader],
          }),
          },
          footers: {
            first: new Footer({
              children: [letterFooter],
              
          }),
            default: new Footer({
              children: [
                new Paragraph({
                  children: [ restPageFooter],
                alignment: AlignmentType.CENTER, 
                }),
              ],
            }),
          },    
          children: [
         //   pageHeader,
            letterAddress,
            letterHeading,
            this.docMainBody(),
            //...htmlMainBody,
            this.docTable(),
            this.getCommentsDoc(),
          // this.getGeothermCommentForWord(),
          //  ...htmlBodyGeothermComment,
            this.getRecommendationForWord(),
            ...htmlBodyRecommendation,
            this.getPleaseContactComment(),
            letterSignature,
            ...this.putImagesInDoc(),
          ],
        },
      ],
     
    });

    // Save the document
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${this.title}.docx`);
    });
  }

  formatEmailList(emailString: string): string {
    // Split the string by commas and trim any extra spaces
    const emails = emailString.split(',').map((email) => email.trim());
    // Join them back with a comma and an extra space
    return emails.join(', ');
  }

  putImagesInDoc() {
    const figureImages = [];
    for (let i = 0; i < this.imageCount; i++) {
      const canvas = document.getElementById(`canvas${i}`) as HTMLCanvasElement;
      figureImages.push({
        image: canvas.toDataURL('image/png', 1.0),
      });
    }
    this.figureImages = [...figureImages];
    const imageArray: any[] = [];
    this.figureImages.forEach((images, i) => {
      imageArray.push(
        new Table({
          rows: [
            // new TableRow({
            //   children: [
            //     new TableCell({
            //       children: [
            //         new Paragraph({
            //           pageBreakBefore: true,
            //           children: [
            //             new ImageRun({
            //               data: this.logo,
            //               transformation: {
            //                 width: 250,
            //                 height: 65,
            //               },
            //             }),
            //           ],
            //           alignment: 'left',
            //         }),
            //       ],
            //       verticalAlign: VerticalAlign.CENTER,
            //       width: {
            //         size: 100,
            //         type: WidthType.PERCENTAGE,
            //       },
            //       borders: {
            //         top: {
            //           style: 'nil',
            //         },
            //         bottom: {
            //           style: 'nil',
            //         },
            //         left: {
            //           style: 'nil',
            //         },
            //         right: {
            //           style: 'nil',
            //         },
            //       },
            //     }),
            //   ],
            // }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      pageBreakBefore: true,
                      children: [
                        new TextRun({
                          text: '',
                          break: 1,
                        }),
                        new TextRun({
                          text: `THERMAL DRYOUT CURVE - FIGURE ${i + 1}`,
                          bold: true,
                          font: this.commonStyle.font,
                          size: this.commonStyle.size,
                        }),
                        new TextRun({
                          text: '',
                          break: 2,
                        }),
                      ],
                      alignment: 'center',
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {
                      style: 'nil',
                    },
                    bottom: {
                      style: 'nil',
                    },
                    left: {
                      style: 'nil',
                    },
                    right: {
                      style: 'nil',
                    },
                  },
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: images.image,
                          transformation: {
                            width: 600,
                            height: 600,
                          },
                        }),
                      ],
                      alignment: 'center',
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {
                      style: 'nil',
                    },
                    bottom: {
                      style: 'nil',
                    },
                    left: {
                      style: 'nil',
                    },
                    right: {
                      style: 'nil',
                    },
                  },
                }),
              ],
            }),
          ],
        }),
      );
    });
    return imageArray;
  }

  getCommentsDoc() {
    if (this.soilType === SoilType.Precast) {
      return this.getPreacastComment();
    }
    return this.getNativeComment();
  }

  getPreacastComment() {
    const comments = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Comments: ',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {
          },
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart1 ? 'Concrete: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart1 ? this.previewData.commentPart1 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        ...(this.previewData.commentPart1 ? [new TextRun({ text: '', break: 2 })] : []),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        new TextRun({
          text: this.previewData.commentPart2 ? 'FTB: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart2 ? this.previewData.commentPart2 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        ...(this.previewData.commentPart2 ? [new TextRun({ text: '', break: 2 })] : []),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        new TextRun({
          text: this.previewData.commentPart3 ? 'Grout/Slurry: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart3 ? this.previewData.commentPart3 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        ...(this.previewData.commentPart3 ? [new TextRun({ text: '', break: 2 })] : []),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        new TextRun({
          text: this.previewData.commentPart4 ? 'Other: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart4 ? this.previewData.commentPart4 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        // new TextRun({
        //   text: this.previewData.commentPart5 ? this.previewData.commentPart5 : '',
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
      ],
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
    return comments;
  }

  getNativeComment() {
    const comments = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Comments: ',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {
          },
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart1,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart3 ? this.previewData.commentPart3 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        // new TextRun({
        //   text: this.previewData.commentPart2,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
      ],
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
    return comments;
  }

  getPleaseContactComment(){
    if (this.soilType === SoilType.Precast) {
      return this.getPrecastPleaseContact();
    } else{
      return this.getNativePleaseContact();
    }
  }
  getPrecastPleaseContact(){
    const comments = new Paragraph({
      children: [
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        new TextRun({
          text: this.previewData.commentPart5 ? this.previewData.commentPart5 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
    return comments;
  }
  getNativePleaseContact(){
    const comments = new Paragraph({
      children: [
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
        new TextRun({
          text: this.previewData.commentPart2,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
    return comments;
  }

  docMainBody() {
    if (this.soilType === SoilType.Precast) {
      return this.docMainBodyPrecast();
    }
    return this.docMainBodyNativeSoil();
  }

  docMainBodyNativeSoil() {
    const mainBody = this.previewData.mainBody;
    const keyword = "IEEE standard 442-2017";
    const companyName = this.previewData?.companyName || ''; 
    const beforeKeyword = mainBody.split(keyword)[0];
    const afterKeyword = mainBody.split(keyword)[1];
     // Replace occurrences of companyName in the mainBody with bolded companyName
  const beforeCompanyName = beforeKeyword.split(companyName)[0];
  const afterCompanyName = beforeKeyword.split(companyName)[1];
    const firstPara = new Paragraph({
      children:[
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.mainBodyIntro,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      alignment: AlignmentType.JUSTIFIED,
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    })
    const secondPara =new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Thermal Resistivity Tests: ',
          bold: true,
          underline: {
          },
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        // new TextRun({
        //   text: this.previewData.mainBody,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
        new TextRun({
          text: beforeCompanyName,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: companyName,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold:true,
        }),
        new TextRun({
          text: afterCompanyName,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        //  new TextRun({
        //   text: beforeKeyword,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
        new TextRun({
          text: keyword,
          font: this.commonStyle.font,
          bold:true,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: afterKeyword,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
      ],
      alignment: AlignmentType.JUSTIFIED,
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
    const thirdPara = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Sample ID, Description, Thermal Resistivity, Moisture Content and Density',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        // new TextRun({
        //   text: '',
        //   break: 1,
        // }),
      ],
      alignment: AlignmentType.JUSTIFIED,
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });
  const mainBodyNative = new Paragraph({
    children:[
      new TextRun({
        text: '',
      }),
      firstPara,
      secondPara,
      thirdPara,
      
    ]
    });
    
    return mainBodyNative;
  }

  docMainBodyPrecast() {
    const mainBody = this.previewData.mainBody;
    const keyword = "IEEE standard 442-2017";
    const beforeKeyword = mainBody.split(keyword)[0];
    const afterKeyword = mainBody.split(keyword)[1];
  
    const firstPortion = new Paragraph({
      children: [
        new TextRun({
          text: beforeKeyword,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: keyword,
          font: this.commonStyle.font,
          bold:true,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: afterKeyword,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      alignment: AlignmentType.JUSTIFIED,
  });
  // Create the last line paragraph (left-aligned)
  const lastPortion = new Paragraph({
    children: [
      new TextRun({
        text: '',
        break: 1,
      }),
      new TextRun({
        text: 'Test Results:',
        font: this.commonStyle.font,
        size: this.commonStyle.size,
        bold: true,
        underline: {},
      }),
      // new TextRun({
      //   text: '',
      //   break: 1,
      // }),
    ],
  });
    const mainBodyPrecast = new Paragraph({
      children: [
        // new TextRun({
        //   text: this.previewData.mainBody,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
        
        // new TextRun({
        //   text: beforeKeyword,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
        // new TextRun({
        //   text: keyword,
        //   font: this.commonStyle.font,
        //   bold:true,
        //   size: this.commonStyle.size,
        // }),
        // new TextRun({
        //   text: afterKeyword,
        //   font: this.commonStyle.font,
        //   size: this.commonStyle.size,
        // }),
        new TextRun({
          text: '',
          break: 1,
        }),        
        firstPortion,
       
        lastPortion
      ],
     
      spacing: {
        line: 300, // Line spacing (in twips) - 240 twips = 12pt
      },
    });

    return mainBodyPrecast;
  }

  docTable() {
    const tableValue = new Paragraph({
      children: [],
      alignment: 'center',
    });
    if (this.soilType === SoilType.Precast) {
      const precastTable = new Table({
        rows: [
          new TableRow({
            height: { value: 800, rule: "exact" },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Sample ID',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Date Cast',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Material Type',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Moisture Content',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry Density',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(lb/ft3)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Thermal Resistivity',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(°C-cm/W)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                columnSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
            ],
          }),

          new TableRow({
            height: { value: 400, rule: "exact" },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Wet',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
            ],
          }),
          ...this.getPrecastRows(),
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
      });
      return precastTable;
    }
    if (this.soilType === SoilType.Native) {
      const nativeTable = new Table({
        rows: [
          new TableRow({
            height: { value: 800, rule: "exact" },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Sample ID',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Depth (ft)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Effort (%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Description',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Thermal Resistivity',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(°C-cm/W)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                columnSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Moisture Content',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry Density',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '(lb/ft3)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
            ],
          }),

          new TableRow({
            height: { value: 400, rule: "exact" },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Wet',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F1F1F1',
                },
              }),
            ],
          }),
          ...this.getNativeRows(),
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
      });
      return nativeTable;
    }
    return tableValue;
  }

  getNativeRows(): TableRow[] {
    return this.forPdfTableDta.map(
      (item) => new TableRow({
        height: { value: 800, rule: "exact" },
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.sampleId,
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.depth?.toString() || '',
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.effort?.toString() || '',
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.description || '',
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.wet.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.dry.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.moistureContent.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.density.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    );
  }

  getPrecastRows(): TableRow[] {
    return this.forPdfTableDta.map(
      (item) => new TableRow({
        height: { value: 800, rule: "exact" },
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.sampleId,
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.collectionDate || '',
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.matrialType || '',
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.moistureContent.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.density.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.wet.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.dry.toString(),
                    size: this.commonStyle.tableFontsize,
                    font: this.commonStyle.tableFont,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    );
  }

  public stripHtml = (html: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || '';
  };

  getGeothermCommentForWord() {
    const geothermCommentContent = this.previewData.geothermComment;
    const geoComment = new Paragraph({
      children: [],
    });
    if (geothermCommentContent) {
      // const plainText = this.stripHtml(geothermCommentContent);
      // const plainText = geothermCommentContent;
      const value = new Paragraph({
        children: [
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: 'Geotherm Comments: ',
            font: this.commonStyle.font,
            size: this.commonStyle.size,
            bold: true,
            underline: {
            },
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
          // new TextRun({
          //   text: plainText,
          //   font: this.commonStyle.font,
          //   size: this.commonStyle.size,
          // }),
        ],
      });
      return value;
    }
    return geoComment;
  }

  getRecommendationForWord() {
    const recommendationCommentContent = this.previewData.recommendations;
    const recommendationComment = new Paragraph({
      children: [],
    });
    if (recommendationCommentContent) {
      //   const plainText = this.stripHtml(recommendationCommentContent);
      const value = new Paragraph({
        children: [
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: 'Recommendation: ',
            font: this.commonStyle.font,
            size: this.commonStyle.size,
            bold: true,
            underline: {
            },
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
          // new TextRun({
          //   text: plainText,
          //   font: this.commonStyle.font,
          //   size: this.commonStyle.size,
          // }),
        ],
      });
      return value;
    }
    return recommendationComment;
  }
  
  ngOnDestroy(): void {
    // avoid memory leakage please don't remove the line
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  //= ============================================
  private parseHtmlToDocx(nodes: HTMLCollection): Paragraph[] {
    const paragraphs: Paragraph[] = [];

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      // Handle paragraph tags <p>
      if (node.nodeName === 'P') {
        const alignment = this.extractAlignment(node); // Extract alignment
        const textRuns = this.parseTextRuns(node);
        paragraphs.push(
          new Paragraph({
            children: textRuns,
            alignment,
          }),
        );
      }

      // Handle heading tags <h1>, <h2>, etc.
      if (node.nodeName.startsWith('H')) {
        const level = parseInt(node.nodeName[1], 10);
        const textRuns = this.parseTextRuns(node);
        paragraphs.push(
          new Paragraph({
            children: textRuns,
            heading: this.mapHeadingLevel(level),
          }),
        );
      }

      // Handle ordered <ol> and unordered <ul> lists
      if (node.nodeName === 'OL' || node.nodeName === 'UL') {
        const isOrdered = node.nodeName === 'OL';
        const listItems = this.parseListItems(node, isOrdered);
        paragraphs.push(...listItems);
      }
    }

    return paragraphs;
  }

  // Function to parse inline text formatting like <strong>, <em>, <u>, <s>, and <a>
  private parseTextRuns(element: Element): (TextRun | ExternalHyperlink)[] {
    const textRuns: (TextRun | ExternalHyperlink)[] = [];

    for (const child of element.childNodes) {
      if (child.nodeType === Node.TEXT_NODE) {
        // Plain text node
        textRuns.push(
          new TextRun({
            text: child.textContent || '',
            font: this.commonStyle.font,
            // size: this.commonStyle.size,
            color: this.commonStyle.color,
          }),
        );
      }

      if (child.nodeType === Node.ELEMENT_NODE) {
        const childElement = child as HTMLElement;

        // Handle bold <strong>
        if (childElement.nodeName === 'STRONG') {
          textRuns.push(
            new TextRun({
              text: childElement.textContent || '',
              bold: true,
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            }),
          );
        }

        // Handle italic <em>
        if (childElement.nodeName === 'EM') {
          textRuns.push(
            new TextRun({
              text: childElement.textContent || '',
              italics: true,
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            }),
          );
        }

        // Handle underline <u>
        if (childElement.nodeName === 'U') {
          textRuns.push(
            new TextRun({
              text: childElement.textContent || '',
              underline: {
              },
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            }),
          );
        }

        // Handle strikethrough <s>
        if (childElement.nodeName === 'S') {
          textRuns.push(
            new TextRun({
              text: childElement.textContent || '',
              strike: true,
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            }),
          );
        }

        // Handle links <a>
        if (childElement.nodeName === 'A') {
          const linkText = childElement.textContent || '';
          const linkUrl = childElement.getAttribute('href') || '';
          const link = new ExternalHyperlink({
            children: [
              new TextRun({
                text: linkText,
                color: '0000FF',
                underline: {
                },
                font: this.commonStyle.font,
                size: this.commonStyle.size,
              }),
            ],
            link: linkUrl,
          });
          textRuns.push(link); // Push the ExternalHyperlink, not a TextRun
        }

        // Handle images <img>
        if (childElement.nodeName === 'IMG') {
          const src = childElement.getAttribute('src') || '';
          const base64Data = this.extractBase64Data(src);
          const imageBuffer = this.convertBase64ToBuffer(base64Data);
          const image = new ImageRun({
            data: imageBuffer,
            transformation: {
              width: 500, // Set image width
              height: 300, // Set image height
            },
          });
          textRuns.push(image);
        }

        // Handle code block <code>
        if (childElement.nodeName === 'CODE') {
          textRuns.push(
            new TextRun({
              text: childElement.textContent || '',
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            }),
          );
        }

        if (childElement.nodeName === 'SPAN') {
          // textRuns.push(
          //   new TextRun({
          //     text: childElement.textContent || '',
          //     font: this.commonStyle.font,
          //     size: this.commonStyle.size,
          //   }),
          // );
          textRuns.push(this.createTextRun(childElement.textContent || '', childElement));
        }
      }
    }
    return textRuns;
  }

  //-----------
  // Paragraph alignment
  private extractAlignment(element: Element): any {
    const { classList } = (element as HTMLElement);

    if (classList.contains('ql-align-center')) {
      return AlignmentType.CENTER;
    } if (classList.contains('ql-align-right')) {
      return AlignmentType.RIGHT;
    } if (classList.contains('ql-align-justify')) {
      return AlignmentType.JUSTIFIED;
    }
    return AlignmentType.LEFT;
  }

  //--------
  // Helper function to create a TextRun with font, size, color, and background color
  private createTextRun(text: string, element: Element): TextRun {
    const { color, backgroundColor } = this.extractStyles(element);

    return new TextRun({
      text,
      font: {
        name: 'Arial',
      },
      size: 24, // 12pt
      color: color || '000000', // Default to black if no color specified
      shading: backgroundColor ? {
        type: ShadingType.CLEAR, color: 'auto', fill: backgroundColor,
      } : undefined,
    });
  }

  // Helper function to extract color and background color from a style attribute
  private extractStyles(element: Element) {
    const style = (element as HTMLElement).getAttribute('style') || '';

    // Parse the style into a map of key-value pairs, filtering out invalid entries
    const styles = new Map<string, string>(
      style.split(';').map((rule): [string, string] | null => {
        const parts = rule.split(':').map((part) => part.trim());
        return parts.length === 2 ? [parts[0], parts[1]] : null; // Ensure only valid pairs are returned
      }).filter((pair): pair is [string, string] => pair !== null), // Filter out invalid pairs
    );

    const color = styles.get('color') ? this.rgbToHex(styles.get('color') as string) : '';
    const backgroundColor = styles.get('background-color') ? this.rgbToHex(styles.get('background-color') as string) : '';

    return {
      color, backgroundColor,
    };
  }

  // Helper function to convert rgb/rgba color values to hex
  private rgbToHex(rgb: string): string {
    const rgbValues = rgb.match(/\d+/g);
    if (!rgbValues) return '000000'; // Default to black
    return rgbValues
      .slice(0, 3)
      .map((val) => parseInt(val).toString(16).padStart(2, '0'))
      .join('');
  }

  //----------

  // Function to parse <ol> and <ul> list items into DOCX format
  private parseListItems(listElement: Element, isOrdered: boolean): Paragraph[] {
    const listItems: Paragraph[] = [];

    for (const item of listElement.children) {
      // Strip non-essential tags like <span class="ql-ui">
      const listItemText = this.stripNonContentTags(item);

      // Add the cleaned-up text as a DOCX list item
      listItems.push(
        new Paragraph({
          children: [
            new TextRun({
              text: listItemText,
              font: {
                name: this.commonStyle.font,
              },
              size: this.commonStyle.size,
            }),
          ],
          numbering: {
            reference: isOrdered ? 'ordered-list' : 'bullet-list',
            level: 0, // Assuming a single-level list; adjust if needed
          },
        }),
      );
    }

    return listItems;
  }

  // Helper function to strip non-content tags like <span class="ql-ui">
  private stripNonContentTags(element: Element): string {
    const clonedElement = element.cloneNode(true) as HTMLElement;
    clonedElement.querySelectorAll('span.ql-ui').forEach((span) => span.remove());
    return clonedElement.textContent?.trim() || '';
  }

  // Helper function to map HTML heading levels <h1>, <h2>, etc. to DOCX HeadingLevel
  private mapHeadingLevel(htmlLevel: number) {
    switch (htmlLevel) {
      case 1:
        return HeadingLevel.HEADING_1;
      case 2:
        return HeadingLevel.HEADING_2;
      case 3:
        return HeadingLevel.HEADING_3;
      case 4:
        return HeadingLevel.HEADING_4;
      case 5:
        return HeadingLevel.HEADING_5;
      case 6:
        return HeadingLevel.HEADING_6;
      default:
        return HeadingLevel.HEADING_1;
    }
  }

  // Helper function to extract Base64 data from an img src
  private extractBase64Data(src: string): string {
    const base64Match = src.match(/^data:image\/(png|jpeg|jpg);base64,(.+)$/);
    return base64Match ? base64Match[2] : '';
  }

  // Helper function to convert Base64 to binary data
  private convertBase64ToBuffer(base64Data: string): ArrayBuffer {
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  //= ============================================
}
