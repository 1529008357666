/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SoilType } from 'src/app/shared/models/soil-type.enum';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/shared/services/form.service';
import { BehaviorSubject, Subscription, forkJoin, take } from 'rxjs';
import { INativeSample } from 'src/app/shared/models/i-native-sample.modal';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { UserService } from 'src/app/shared/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { IMessage } from 'src/app/shared/models/message.modal';
import { IProject } from 'src/app/shared/models/i-project.modal';
import { IDropdown } from 'src/app/shared/models/i-dropdown-type.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { IPrecastSample } from 'src/app/shared/models/i-precast-sample.modal';
import { IAttachment } from 'src/app/shared/models/i-attachment.modal';
import { IBilling } from 'src/app/shared/models/i-billing.modal';
import { NotesComponent } from 'src/app/shared/components/notes/notes.component';
import { INotes } from 'src/app/features/models/inotes.modal';
import { PrecastSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { WorksheetService } from 'src/app/features/worksheet/services/worksheet.service';
import { ReviewPayload, Sample } from 'src/app/shared/models/worksheet-checkbox.model';
import { ICommentModel, IInstructionCommentData } from 'src/app/shared/models/i-comment-modal';
import { AdditionalCommentComponent } from 'src/app/shared/components/additional-comment/additional-comment.component';
import { DeleteService } from 'src/app/shared/services/delete.sevice';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateUtilService } from 'src/app/shared/pipes/date-utc.pipe';
import { HttpParams } from '@angular/common/http';
import { SharedService } from 'src/app/features/service/shared.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { SubmissionSuccessComponent } from '../submission-success/submission-success.component';
import { PrintInstructionsComponent } from '../print-instructions/print-instructions.component';
import { nativeColumns } from '../../constants/native-soil-table-columns';
import { precastColumns } from '../../constants/precast-table-columns';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  public pageLeaveDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  soilType = '';
  title = '';
  instructionTitle = '';
  sampleTitle = '';
  instructionData: IInstructionCommentData;
  companyInformation: ICompany;
  dialogRef: any;
  displayedColumns: string[] = [];
  headerColumns: object[] = [];
  formData: IPrecastRecord;
  dataSource: INativeSample[] | IPrecastSample[];
  billingInformation: IBilling;
  editLink = '/sampleform/';
  userAccount: any;
  formSubscription: Subscription;
  public updateSuccess: MatDialogRef<MessageModalComponent>;
  public createSuccess: MatDialogRef<SubmissionSuccessComponent>;
  public printInstructions: MatDialogRef<PrintInstructionsComponent>;
  submitText = 'SUBMIT';
  nativeSoilSampleTypes: IDropdown[];
  enableUpdateButton = true;
  generateFlag: boolean | undefined;
  fromWhere: string | null;
  toWorksheetLink = '/worksheet?soilType=';
  projectId: string;
  projectStatuses: IDropdown[] = [];
  enableSampleEdit = new BehaviorSubject<boolean | null>(true);
  checkProjectStatus = true;
  editEnable: string | null;
  enablePrint = false;
  printSuccess = false;
  showSubmitButton = true;
  enableSubmitButton = true;
  enableInstructionEdit = new BehaviorSubject<boolean | null>(true);
  enableCompanyEdit = new BehaviorSubject<boolean | null>(true);
  update = false;
  cancelOrClose = false;
  showPrintHints = true;
  instructionsReceived = false;
  showPrintSuccessMessage = false;
  isMergeDisabled = false;
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.printSuccess) {
      window.location.reload();
    }
  }

  userRoles: string[] = [];
  enableMissingButton = true;
  notes: INotes[] = [];
  editViewSubmission = false;
  samples: Sample[] = [];
  newSubmissionInformation: ReviewPayload;
  viewSubmissionInfo: ReviewPayload;
  Addcomments: ReviewPayload;
  tableViewSubmission: Sample[];
  Inhouseform: FormGroup;
  currentPrefix = '';
  isInputChanged = false;
  disablePrefix = false;
  isPrefixValid = false;
  prefixError = false;
  enableGenerateBtn = true;
  yesterday = new Date();
  isDueDateSet = false;
  precastSampleTypes : PrecastSampleType [] = [];
  filteredNativeSamples: INativeSample[] = [];
  filteredPrecastSamples: IPrecastSample[] = [];
  isCheckboxChecked = false;
  splitProject: Sample[];
  isFlagged = false;
  isSplitAction = false;
  splitEnabled = false;
  public proceedWorksheet: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private formService: FormService,
    private precastService: PrecastService,
    public userService: UserService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private worksheetService: WorksheetService,
    private deleteService: DeleteService,
    private fb: FormBuilder,
    private utcData: DateUtilService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private lookupService:LookupService,
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 0);
    this.Inhouseform = this.fb.group({
      dueDate: [''],
      inHouseIdPrefix: [''],
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('submission-flagged')) {
      const flag = sessionStorage.getItem('submission-flagged');
      if (flag) {
        this.isFlagged = true;
      }
    }
    if (sessionStorage.getItem('split-submission')) {
      const split = sessionStorage.getItem('split-submission');
      if (split) {
        this.isSplitAction = true;
      }
    }
    const sampleType = this.activatedRoute.snapshot.paramMap.get('sampletype');
    this.editEnable = this.activatedRoute.snapshot.paramMap.get('e');
    this.projectId = this.activatedRoute.snapshot.paramMap.get('id') || '';
    this.getPrecastSampleTypes();
    this.setSoilType(sampleType);
    this.initializeSubmissionView();
    if (this.soilType === 'nativesoil' || this.soilType === 'precast') {
      this.setSoilConfigData(this.projectId);
      this.fromWhere = this.activatedRoute.snapshot.queryParamMap.get('f');
      if (this.fromWhere === 'w') {
        this.fromWhere = 'w';
      } else {
        this.fromWhere = 'd';
      }
    }

    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
    this.setuserAccount();
    if (this.projectId) {
      this.fetchNotes(this.projectId);
      if (this.roleCheck(['Admin', 'Manager'])) {
        if (this.soilType === 'precast') {
          this.getPrecastViewSubmission(this.projectId);
        } else {
          this.getNativeSoilViewSubmission(this.projectId);
        }
      }
    }
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  setuserAccount(): void {
    this.userService.userAccount.subscribe({
      next: (account) => {
        this.userAccount = account;
        if (this.checkProjectStatus) {
          this.checkProjectStatus = false;
          if (this.userAccount) {
            this.fetchReviewDataWithAuthorized(this.projectId);
          } else {
            this.fetchReviewDataWithoutAuthorized(this.projectId);
          }
        }
      },
    });
  }

  setSoilType(sampleType: string | null): void {
    if (sampleType === 'nativesoil') {
      this.soilType = 'nativesoil';
    } else if (sampleType === 'precast') {
      this.soilType = 'precast';
    } else {
      this.router.navigate(['/']);
    }
  }

  getPrecastViewSubmission(projectId: string) {
    this.worksheetService?.getPrecastViewSubmissionWorksheet(projectId)?.subscribe({
      next: (response) => {
        if (response) {
          this.enableMissingButton = false;
          this.viewSubmissionInfo = response;
          this.tableViewSubmission = response.samplesInfo;
        }
      },
      error: (error) => {
        console.error('Error fetching precast view submission:', error);
      },
    });
  }

  getNativeSoilViewSubmission(projectId: string) {
    this.worksheetService?.getNativeSoilViewSubmissionWorksheet(projectId)?.subscribe({
      next: (response) => {
        if (response) {
          this.enableMissingButton = false;
          this.viewSubmissionInfo = response;
          this.tableViewSubmission = response.samplesInfo;
        }
      },
    });
  }

  fetchReviewDataWithAuthorized(projectId: string | null): void {
    this.showSubmitButton = true;
    const sampleTypes$ = this.precastService?.fetchNativeSampleTypes();
    const projectStatuses$ = this.precastService.fetchProjectStatuses().pipe(take(1));
    forkJoin({
      sampleTypes: sampleTypes$,
      projectStatuses: projectStatuses$,
    }).subscribe({
      next: ({ sampleTypes, projectStatuses }) => {
        this.setProjectStatusArray(projectStatuses);
        this.setDataForReview(sampleTypes, projectId);
      },
    });
  }

  setProjectStatusArray(projectStatuses: IDropdown[]): void {
    projectStatuses.forEach((elem: IDropdown) => {
      switch (elem.label) {
        case 'Samples Due To Arrive':
        case 'Shipment Received':
        case 'Awaiting Instructions':
          this.projectStatuses.push(elem);
          break;
        default:
      }
    });
  }

  fetchReviewDataWithoutAuthorized(projectId: string | null): void {
    this.precastService?.fetchNativeSampleTypes().subscribe({
      next: (sampleTypes) => {
        this.setDataForReview(sampleTypes, projectId);
        setTimeout(() => {
          this.checkProjectStatus = true;
        }, 5);
      },
    });
  }

  setDataForReview(sampleTypes: IDropdown[], projectId: string | null): void {
    let redirect = false;
    this.nativeSoilSampleTypes = sampleTypes;
    this.formSubscription = this.formService.savedData$?.subscribe((data: IPrecastRecord | null) => {
      if (data && data?.company) {
        this.setIDForUpdate(data);
        this.setFormData(data);
        this.fetchFormData();
      } else if (!data && !projectId) {
        redirect = true;
      } else {
        this.enableUpdateButton = false;
        this.enableSubmitButton = false;
      }
    });
    if (redirect) {
      this.redirectTo();
    } else if (!projectId) {
      this.submitText = 'SUBMIT';
      this.showPrintHints = true;
      if (!this.formData) {
        const data = JSON.parse(localStorage.getItem('reviewForm') || '{}');
        if (data && data?.company) {
          this.setFormData(data);
          this.formService.updateData(this.formData);

          localStorage.setItem('reviewForm', JSON.stringify(this.formData));
        }
      }
    } else {
      if (projectId && (!this.formData || Object.keys(this.formData).length === 0)) {
        this.formService.fetchFormData(projectId, this.soilType).subscribe({
          next: (data) => {
            if (data) {
              this.generateFlag = data.project.worksheetGenerated;
              this.enableGenerateBtn = this.soilType === 'precast' && !data.project.inHouseIdPrefix;
              this.setFormData(data);
              this.formData.samples?.forEach((elem) => {
                if (this.soilType === 'precast' && elem.sampleCollectionDate) {
                  elem.sampleCollectionDate = this.getActualDate(elem.sampleCollectionDate);
                }
                if (this.soilType === 'nativesoil' && elem.collectionDate) {
                  elem.collectionDate = this.getActualDate(elem.collectionDate);
                }
              });
              this.formService.updateData(this.formData);
            }
          },
          error: () => {},
        });
      }
      this.submitText = 'UPDATE';
      this.showPrintHints = false;
    }

    this.setShowItemForAuthorizedUser();
  }

  setShowItemForAuthorizedUser(): void {
    if (this.userAccount) {
      this.enableSubmitButton = true;
      this.showPrintHints = false;
    }
  }

  getActualDate(date: string | Date): Date {
    const utcDate = new Date(date);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    return localDate;
  }

  setIDForUpdate(data: any) {
    if (data?.company?.id) {
      data.company.companyId = data.company.id;
    }

    if (data?.project?.id) {
      data.project.projectId = data.project.id;
    }
  }

  redirectTo(): void {
    if (this.userAccount) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/customer']);
    }
  }

  setSoilConfigData(projectId: string | null): void {
    if (this.soilType === 'nativesoil') {
      this.headerColumns = nativeColumns;
      this.headerColumns.forEach((elem: any) => {
        this.displayedColumns.push(elem.name);
      });
      this.title = `${SoilType.NativeSoil} Sample Form`;
      this.instructionTitle = 'Additional comments from client';
      this.sampleTitle = 'Material/Sample Information';
      this.editLink = `${this.editLink}native-soil/edit/${projectId}`;
    } else if (this.soilType === 'precast') {
      this.headerColumns = precastColumns;
      this.headerColumns.forEach((elem: any) => {
        this.displayedColumns.push(elem.name);
      });
      this.instructionTitle = 'Instructions by sample type';
      this.title = `${SoilType.Precast} Sample Form`;
      this.sampleTitle = 'Material/Sample Information';
      this.editLink = `${this.editLink}precast/edit/${projectId}`;
      this.soilType = 'precast';
    }
  }

  setFormData(data: IPrecastRecord): void {
    this.formData = data;
    if (
      this.formData.project.dueDate !== null
      && this.formData.project.dueDate !== ''
      && this.formData.project.dueDate !== undefined
    ) {
      this.isDueDateSet = true;
    }

    this.currentPrefix = '';
    const localDate = this.convertUtcToLocal(this.formData.project.dueDate || '');
    const inhousePrefix = this.formData.project.inHouseIdPrefix;
    let sortedComments;
    if (this.soilType === 'precast') {
      sortedComments = this.sortComments(this.formData.comments);
    } else {
      sortedComments = this.formData.comments;
    }
    this.instructionData = {
      id: this.formData.project.id,
      comments: sortedComments,
      instructionsConfirmed: false,
      inHouseIdPrefix: '',
    };
    this.instructionData.instructionsConfirmed = this.formData.project?.instructionsConfirmed;
    this.formData.company.contactNumber = this.formData.company.contactNumber.slice(0, 10);
    this.formData.company.bcontactNumber = this.formData?.company?.bcontactNumber?.slice(0, 10);
    this.instructionsReceived = this.instructionData?.instructionsConfirmed
      ? this.instructionData?.instructionsConfirmed
      : false;
    this.companyInformation = {
      ...this.formData.company,
      ...this.formData.project,
    };
    this.Inhouseform.patchValue({
      dueDate: localDate,
      inHouseIdPrefix: inhousePrefix || '',
    });
    this.Inhouseform.get('inHouseIdPrefix')?.setValue(this.currentPrefix);
    this.billingInformation = {
      ...this.formData.billing,
    };

    if (inhousePrefix === null || inhousePrefix === '') {
      this.disablePrefix = false;
    } else {
      this.disablePrefix = true;
    }
    if (this.formData.samples) {
      this.dataSource = [];
      this.formData.samples.forEach((elem: IPrecastSample | INativeSample) => {
        let el: any;
        if (this.soilType === 'nativesoil') {
          el = this.setSampleDataForDisplay(elem);
        } else {
          el = {
            ...elem,
          };
        }
        this.dataSource.push(el);
      });
    }
    if (this.projectId && this.formData?.project?.projectStatusTypeId) {
      if (this.soilType === 'precast') {
        this.enableSampleEdit.next(true);
        if (!this.formData.project.projectStatusTypeId) {
          if (this.editEnable === '0') {
            this.enableSampleEdit.next(false);
          }
        }
      } else {
        this.enableSampleEdit.next(true);
      }
    } else {
      this.enableSampleEdit.next(true);
    }
    if (!this.projectId) {
      this.enableSubmitButton = this.setUpdateButtonEnable(this.enableUpdateButton);
    }
    if (this.userAccount && this.userService.roleCheck(['Reportwriter'])) {
      this.enableSampleEdit.next(false);
      this.enableCompanyEdit.next(false);
      this.enableInstructionEdit.next(false);
      this.showSubmitButton = false;
    }
  }

  sortComments(comments: any): ICommentModel [] {
    const sortedComments :ICommentModel [] = [];
    this.precastSampleTypes.forEach((sComment: PrecastSampleType) => {
      const item = comments.find((c:ICommentModel) => c.sampleTypeMasterId === sComment.id);
      if (item) {
        sortedComments.push(item);
      }
    });
    return sortedComments;
  }

  fetchFormData() {
    if (this.formData && this.formData.project) {
      this.currentPrefix = this.formData.project.inHouseIdPrefix || '';
      this.Inhouseform.get('inHouseIdPrefix')?.setValue(this.currentPrefix);
      this.isPrefixValid = !!this.currentPrefix;
    }
  }

  formatDateForDisplay(date?: string): string {
    if (!date) return '';
    return this.utcData.convertUtcToLocal(date, 'date');
  }

  convertUtcToLocal(utcDateString: string): Date {
    if (!utcDateString) return new Date();
    const utcDate = new Date(utcDateString);
    return new Date(utcDate.getTime() - new Date().getTimezoneOffset() * 60000);
  }

  onDueDateChange() {
    const projectID = this.projectId;
    const newDate = this.Inhouseform.get('dueDate')?.value;
    const jsDate = new Date(newDate);
    const utcDate = new Date(Date.UTC(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate()));
    this.formData.project.dueDate = utcDate.toISOString();
    const { dueDate } = this.formData.project;
    if (projectID !== '' && projectID !== null) {
      this.precastService.updateDueDate(this.formData, projectID, dueDate)?.subscribe({
        next: (res) => {
          this.snackBar.open(messagesConfig.dueDateUpdated.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
        },
        error: () => {},
      });
    }
  }

  onPrefixChange(event: any) {
    const inputValue = event.target.value.toUpperCase().substring(0, 2);
    this.Inhouseform.get('inHouseIdPrefix')?.setValue(inputValue, {
      emitEvent: false,
    });
    this.currentPrefix = inputValue;
    this.isInputChanged = true;
    // this.formData.project.inHouseIdPrefix = inputValue;
    this.instructionData.inHouseIdPrefix = this.formData.project.inHouseIdPrefix;
    this.prefixError = false;
  }

  setSampleDataForDisplay(elem: any): any {
    const el: any = {
      ...elem,
    };
    if (el.moistureOptimum) {
      el.moistureContent = 'Optimum';
    } else if (el.moistureAsReceived) {
      el.moistureContent = 'In-Situ/As Received';
    } else {
      el.moistureContent = el.specifiedMoistureContent;
    }
    const index = this.nativeSoilSampleTypes.findIndex((x) => x.id === elem.sampleTypeMasterId);
    if (index > -1) {
      el.sampleTypeMasterId = this.nativeSoilSampleTypes[index].id;
    }
    if (el.sampleDepth === '') {
      el.sampleDepth = null;
    }
    if (el.sampleEndDepth && el.sampleEndDepth != null && el.sampleEndDepth !== '') {
      el.displayDepth = `${el.sampleDepth}-${el.sampleEndDepth}`;
    }
    if (el.sampleDepth === undefined && el.sampleEndDepth !== '') {
      el.displayDepth = 'Stockpile';
    }
    if (el.sampleEndDepth == null && !Number.isNaN(el.sampleDepth)) {
      el.displayDepth = el.sampleDepth;
    }
    if (el.stockPile === true) {
      el.displayDepth = 'Stockpile';
    }
    // else if (el.sampleDepth && el.sampleDepth !== '' && !(el.sampleEndDepth && el.sampleEndDepth !== '')) {
    //   el.displayDepth = `${el.sampleDepth}`;
    // } else if (el.sampleEndDepth && el.sampleEndDepth !== '') {
    //   el.displayDepth = `${el.sampleDepth}-${el.sampleEndDepth}`;
    // }
    return el;
  }

  checktoEnableEditButton(id: string | undefined): boolean {
    const index = this.projectStatuses.findIndex((x) => x.id === id);
    if (index > -1) {
      return true;
    }
    return false;
  }

  onCancel(): void {
    let routeTo = '';
    let navigationExtras: NavigationExtras = {
    };
    if (this.fromWhere === 'w') {
      routeTo = !this.userAccount ? '/customer' : '/worksheet';
      const params = {
        soilType: this.soilType,
        projectId: this.projectId,
      };
      navigationExtras = {
        queryParams: params,
      };
    } else {
      routeTo = !this.userAccount ? '/customer' : '/dashboard';
    }
    if (!this.userService.roleCheck(['Reportwriter'])) {
      this.pageLeaveDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
        panelClass: 'worksheet-dialog',
        data: {
          title: 'Cancel Changes',
          content: 'Are you sure you want to cancel? Your Changes will not be saved!',
          cancelText: 'NO, CONTINUE',
        },
        disableClose: true,
        enterAnimationDuration: '0ms',
        exitAnimationDuration: '0ms',
      });
      this.pageLeaveDialogRef?.afterClosed()?.subscribe((response?: string) => {
        if (response === 'YES') {
          this.router.navigate([routeTo], navigationExtras);
          sessionStorage.removeItem('uploadedFile');
        }
      });
    } else {
      this.router.navigate([routeTo], navigationExtras);
    }
  }

  close(): void {
    let routeTo = '';
    let navigationExtras: NavigationExtras = {
    };
    if (this.fromWhere === 'w') {
      routeTo = !this.userAccount ? '/customer' : '/worksheet';
      const params = {
        soilType: this.soilType,
        projectId: this.projectId,
      };
      navigationExtras = {
        queryParams: params,
      };
    } else {
      routeTo = !this.userAccount ? '/customer' : '/dashboard';
    }
    window.history.forward();
    localStorage.removeItem('reviewForm');
    if (!this.userAccount) {
      window.location.reload();
    } else {
      this.router.navigate([routeTo], navigationExtras);
    }
  }

  onSubmit(): void {
    const propertyToExclude = 'companyId';
    const { [propertyToExclude]: excludedProperty, ...payload } = this.formData.company;
    this.formData.company = payload;
    this.formData.samples?.forEach((elem: any) => {
      if (elem?.sampleDepth === '') {
        elem.sampleDepth = null;
      }
      if (!elem?.sampleEndDepth) {
        elem.sampleEndDepth = null;
      }
      if (!elem?.maxDryDensity) {
        elem.maxDryDensity = null;
      }
      if (!elem?.optimumMoistureContent) {
        elem.optimumMoistureContent = null;
      }
      if (!elem?.specifiedMoistureContent) {
        elem.specifiedMoistureContent = null;
      }
      if (elem?.ticketNumber) {
        elem.ticketNumber = elem.ticketNumber.trim();
      }
      if (elem?.truckNumber) {
        elem.truckNumber = elem.truckNumber.trim();
      }

      if (elem?.compactionEffort) {
        elem.compactionEffort = this.removeLastComma(elem?.compactionEffort.trim());
      }
      if (!elem?.collectionDate) {
        elem.collectionDate = null;
      }
    });
    if (!this.projectId) {
      this.showSubmitButton = false;
      if (!this.userAccount) {
        this.enableSampleEdit.next(false);
        this.enableCompanyEdit.next(false);
        this.enableInstructionEdit.next(false);
        this.cancelOrClose = true;
      } else {
        this.enableInstructionEdit.next(true);
        this.enableCompanyEdit.next(true);
        this.enableSampleEdit.next(true);
      }
      this.precastService.addSample(this.formData, this.soilType, this.userAccount)?.subscribe({
        next: (response: any) => {
          let successMessage: IMessage;
          let routeTo = '/customer';
          let containerDetails: string;
          if (this.userAccount) {
            containerDetails = response.project.id;
            if (this.attachedFiles?.valid) {
              this.precastService
                .uploadAttachments(containerDetails, this.attachedFiles.value, this.userAccount)
                .subscribe({
                  next: () => {
                    successMessage = messagesConfig.formSubmitUserSuccessMessage;
                    routeTo = '/dashboard';
                    sessionStorage.removeItem('uploadedFile');
                    this.showUpdatePopUpMessage(successMessage, routeTo);
                    this.showPrintSuccessMessage = true;
                  },
                });
            } else {
              successMessage = messagesConfig.formSubmitUserSuccessMessage;
              routeTo = '/dashboard';
              this.showUpdatePopUpMessage(successMessage, routeTo);
              this.showPrintSuccessMessage = true;
            }
          } else {
            containerDetails = response;
            if (this.attachedFiles?.valid) {
              this.precastService
                .uploadAttachments(containerDetails, this.attachedFiles.value, this.userAccount)
                .subscribe({
                  next: () => {
                    successMessage = messagesConfig.formSubmitSuccessMessage;
                    this.showCreatePopUpMessage(successMessage, routeTo);
                    this.showPrintSuccessMessage = true;
                  },
                });
            } else {
              successMessage = messagesConfig.formSubmitSuccessMessage;
              this.showCreatePopUpMessage(successMessage, routeTo);
              this.showPrintSuccessMessage = true;
            }
          }
          // this.formService.attachments?.next(null);
        },
        error: () => {
          this.showSubmitButton = true;
          this.cancelOrClose = false;
        },
      });
    } else {
      if (this.formData.project) {
        this.formData.project.id = this.projectId || undefined;
      }
      if (payload.bId) {
        this.formData.billing.id = payload.bId;
      }
      const params = new HttpParams({
        fromObject: {
          f: 'd',
        },
      });
      this.formData.project.instructionsConfirmed = this.instructionsReceived;
      this.precastService.updateSample(this.formData, this.soilType)?.subscribe({
        next: () => {
          const successMessage: IMessage = messagesConfig.formSubmitUserSuccessMessage;
          const url = `/review/form/${this.soilType}/${this.projectId}?${params.toString()}`;
          this.showUpdatePopUpMessage(successMessage, url);
          this.enableUpdateButton = false;
          sessionStorage.removeItem('editViewSubmission');
        },
        error: () => {},
      });
    }
  }

  removeLastComma(input: string): string {
    if (input.endsWith(',')) {
      return input.slice(0, -1);
    }
    return input;
  }

  showUpdatePopUpMessage(successMessage: IMessage, routeTo: string): void {
    this.updateSuccess = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.updateSuccess.afterClosed().subscribe(() => {
      if (routeTo) {
        this.sharedService.routeValue(routeTo);
      }
    });
  }

  showWarningPopUpMessage(successMessage: IMessage): void {
    this.updateSuccess = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.updateSuccess.afterClosed().subscribe(() => {
    });
  }

  showCreatePopUpMessage(successMessage: IMessage, routeTo: string): void {
    this.createSuccess = this.dialog.open(SubmissionSuccessComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.createSuccess.afterClosed().subscribe(() => {
      if (!this.userAccount) {
        this.showSubmitButton = false;
      }
    });
  }

  gotoWorksheet(): void {
    const params = {
      soilType: this.soilType,
      projectId: this.projectId,
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
    };
    this.router.navigate(['/worksheet'], navigationExtras);
  }

  back(): void {
    let routeTo = '';
    let navigationExtras: NavigationExtras = {
    };
    if (this.fromWhere === 'w') {
      routeTo = !this.userAccount ? '/customer' : '/worksheet';
      const params = {
        soilType: this.soilType,
        projectId: this.projectId,
      };
      navigationExtras = {
        queryParams: params,
      };
    } else {
      routeTo = !this.userAccount ? '/customer' : '/dashboard';
    }
    this.router.navigate([routeTo], navigationExtras);
  }

  print(): void {
    this.printInstructions = this.dialog.open(PrintInstructionsComponent, {
      panelClass: 'print-dialog',
      data: {
        image: true,
        buttons: true,
        type: this.soilType,
        enablePrint: this.enablePrint,
      },
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      width: '90%',
    });
    this.printInstructions.afterClosed()?.subscribe((data: any) => {
      this.printSuccess = data.printed;
      this.enablePrint = data.enablePrint;
      if (this.userAccount) {
        this.enablePrint = true;
      }
      this.enableSubmitButton = this.setUpdateButtonEnable(this.enableUpdateButton);
      this.showPrintHints = false;
      if (data.printed) {
        window.print();
      }
    });
  }

  attachedFiles: IAttachment;

  selectedAttachment(event: IAttachment) {
    this.attachedFiles = event;
  }

  setUpdateButtonEnable(isButtonEnable: boolean): boolean {
    if (isButtonEnable) {
      if (!this.enablePrint) {
        return false;
      }
    }
    return true;
  }

  printAfter(): void {
    window.print();
  }

  public openDialog(element: any): void {
    const messageObject = {
      projectId: element,
    };
    this.dialogRef = this.dialog?.open<any>(NotesComponent, {
      panelClass: 'app-dialog',
      data: messageObject,
      disableClose: true,
      width: '60%',
    });
    this.dialogRef.afterClosed().subscribe((projectId: any) => {
      if (this.projectId) {
        this.fetchNotes(this.projectId);
      }
    });
  }

  fetchNotes(projectId: string): void {
    this.precastService.getNotesbyId(projectId)?.subscribe({
      next: (res: INotes[]) => {
        this.notes = res;
      },
    });
  }

  getNotesCount(): number {
    return this.notes?.filter((note) => note.roleName !== null)?.length ?? 0;
  }

  initializeSubmissionView(): void {
    const storedSubmissionView = sessionStorage.getItem('editViewSubmission');
    if (storedSubmissionView) {
      this.editViewSubmission = JSON.parse(storedSubmissionView);
    }
  }

  editSubmission() {
    if (this.soilType === 'nativesoil') {
      const url = `sampleform/native-soil/edit/${this.projectId}?s=1&f=d`;
      this.editViewSubmission = true;
      sessionStorage.setItem('editViewSubmission', JSON.stringify(this.editViewSubmission));
      this.router?.navigateByUrl(url);
    } else if (this.soilType === 'precast') {
      const url = `sampleform/precast/edit/${this.projectId}?s=1&f=d`;
      this.editViewSubmission = true;
      sessionStorage.setItem('editViewSubmission', JSON.stringify(this.editViewSubmission));
      this.router?.navigateByUrl(url);
    } else {
      this.editViewSubmission = false;
      sessionStorage.setItem('editViewSubmission', JSON.stringify(this.editViewSubmission));
    }
  }

  viewGenerateSheet() {
    if (this.companyInformation && this.companyInformation.worksheetGenerated) {
      // Logic to show worksheet view
      this.ViewWorksheet();
    } else {
      // Logic to generate worksheet
      this.getGenerateWorksheet();
      // this.generateWorkSheet();
    }
  }

  ViewWorksheet() {
    if (this.projectId) {
      this.router.navigateByUrl(`worksheet?soilType=${this.soilType}&&projectId=${this.projectId}`);
    }
  }

  generateWorkSheet() {
    this.proceedWorksheet = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'worksheet-dialog',
      data: {
        title: 'Generate Worksheet',
        content:
          'Are you sure you want to testing? There are sample that have issues listed. Generating a worksheet now will split this project. Sample with issues will be moved to a new project.',
        cancelText: 'CANCEL',
        continueText: 'PROCEED',
      },
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.proceedWorksheet?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        this.getGenerateWorksheet();
      }
    });
  }

  getGenerateWorksheet() {
    if (this.projectId) {
      if (this.soilType === 'precast') {
        this.worksheetService.generatePrecastWorksheet(this.projectId).subscribe({
          next: () => {
            this.router.navigateByUrl(`worksheet?soilType=${this.soilType}&&projectId=${this.projectId}`);
          },
          error: () => {},
        });
      } else {
        this.worksheetService.generateNativeSoilWorksheet(this.projectId).subscribe({
          next: () => {
            this.router.navigateByUrl(`worksheet?soilType=${this.soilType}&&projectId=${this.projectId}`);
          },
          error: () => {},
        });
      }
    }
  }

  // Notify Issues or missing data
  previewOpenDialog(projectID: any): void {
    sessionStorage.removeItem('uploadedFilesList');
    const messageObject = {
      projectId: projectID,
      contactName: this.companyInformation.contactName,
      projectName: this.companyInformation.projectName,
      projectNo: this.companyInformation.projectNumber,
      fullInfo: this.newSubmissionInformation,
      table: this.samples,
      soilType: this.soilType,
      addComments: this.Addcomments,
    };
    this.dialogRef = this.dialog?.open<any>(AdditionalCommentComponent, {
      panelClass: 'app-dialog',
      data: messageObject,
      disableClose: true,
      width: '50%',
    });
    this.dialogRef.afterClosed().subscribe((projectId: any) => {
    });
  }

  handleSamplesChange(samples: Sample[]) {
    this.samples = samples;
  }

  handleSubmitForm(event: ReviewPayload) {
    this.newSubmissionInformation = event;
  }

  handleComment(event: ReviewPayload) {
    this.Addcomments = event;
  }

  onUpdateSaveAction() {
    const { ...payload } = this.formData.company;
    this.formData.company = payload;
    if (this.formData.project) {
      this.formData.project.id = this.projectId || undefined;
    }
    if (payload.bId) {
      this.formData.billing.id = payload.bId;
    }
    this.formData.project.instructionsConfirmed = this.instructionsReceived;
    this.precastService.updateSample(this.formData, this.soilType)?.subscribe({
      next: (res) => {
        this.enableUpdateButton = false;
        if (typeof res === 'object' && res !== null && 'project' in res) {
          this.generateFlag = res.project?.worksheetGenerated;
          this.companyInformation = {
            ...res.company,
            ...res.project,
          };
          if (!this.generateFlag) {
            // this.activatedRoute.snapshot.queryParamMap.get('f')
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                f: 'd',
              },
              queryParamsHandling: 'merge',
            });
            this.fromWhere = 'd';
          }
        }
      },
      error: () => {},
    });
  }

  onSubmitWorksheet() {
    const combinedData: ReviewPayload = {
      ...this.newSubmissionInformation,
      ...this.Addcomments,
      samplesInfo: [...this.samples],
    };
    if (this.Inhouseform.get('dueDate')?.value !== null || this.Inhouseform.get('dueDate')?.value !== '') {
      this.isDueDateSet = true;
    }
    this.onUpdateSaveAction();
    const params = new HttpParams({
      fromObject: {
        f: 'd',
      },
    });
    if (this.projectId && this.isFlagged) {
      if (this.soilType === 'precast') {
        this.worksheetService.addPrecastViewSubmissionWorksheet(this.projectId, combinedData).subscribe({
          next: (response) => {
            if (response) {
              this.enableMissingButton = false;
              const successMessage: IMessage = messagesConfig.formSubmitUserSuccessMessage;
              const url = `/review/form/${this.soilType}/${this.projectId}?${params.toString()}`;
              this.showUpdatePopUpMessage(successMessage, url);
              sessionStorage.removeItem('submission-flagged');
            }
          },
          error: () => {},
        });
      } else {
        this.worksheetService.addNativeSoilViewSubmissionWorksheet(this.projectId, combinedData).subscribe({
          next: (response) => {
            if (response) {
              this.enableMissingButton = false;
              const successMessage: IMessage = messagesConfig.formSubmitUserSuccessMessage;
              const url = `/review/form/${this.soilType}/${this.projectId}?${params.toString()}`;
              this.showUpdatePopUpMessage(successMessage, url);
              sessionStorage.removeItem('submission-flagged');
            }
          },
          error: () => {},
        });
      }
    }
  }

  checkInhouseId() {
    if (this.currentPrefix) {
      this.precastService.getInhouseId(this.currentPrefix)?.subscribe({
        next: (res: any) => {
          this.isPrefixValid = !res;
          this.prefixError = res;
          this.isInputChanged = false;
          if (res === false) {
            this.disablePrefix = true;
            this.postInhouseId();
          } else {
            this.disablePrefix = false;
          }

          this.cdr.detectChanges();
        },
        error: () => {
          this.isPrefixValid = false;
          this.isInputChanged = false;
        },
      });
    }
  }

  postInhouseId() {
    if (this.currentPrefix) {
      this.formData.project.inHouseIdPrefix = this.currentPrefix;
      this.precastService.postInhouseId(this.formData.project)?.subscribe({
        next: () => {
          this.snackBar.open(messagesConfig.inHouseIDUpdated.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
          this.enableGenerateBtn = false;
        },
        error: () => {},
      });
    }
  }

  shouldShowSearchIcon(): boolean {
    return !!this.currentPrefix && this.currentPrefix.length === 2 && this.isInputChanged;
  }

  shouldShowDoneIcon(): boolean {
    if (this.prefixError || !this.currentPrefix || this.isInputChanged) {
      return false;
    }
    return this.isPrefixValid;
  }

  shouldShowError(): boolean {
    return this.prefixError;
  }

  getPrecastSampleTypes() {
    this.lookupService.getPrecastSampletypes().subscribe({
      next: (res: PrecastSampleType[]) => {
        this.precastSampleTypes = res;
      },
    });
  }

  commonFlagApiService(projectID: string, soilType: string): void {
    const params = {
      f: 'd', // Query parameter for the navigation
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
      // Other navigation options can be added here if needed
    };
    this.formService.updateData(null); // Update form data with null
    // Fetch form data based on projectID and navigateSoilType
    this.formService.fetchFormData(projectID, soilType).subscribe({
      next: (data) => {
        if (data && data.samples && Array.isArray(data.samples)) {
          this.filteredNativeSamples = [];
          this.filteredPrecastSamples = [];

          // Filter samples based on soil type
          data.samples.forEach((sample: any) => {
            if (soilType === 'precast') {
              const precastSample = sample as IPrecastSample;
              this.filteredPrecastSamples.push(precastSample);
            } else {
              const nativeSample = sample as INativeSample;
              this.filteredNativeSamples.push(nativeSample);
            }
          });

          // Check if all sampleStatusTypeId values are null for Pre Cast samples
          if (soilType === 'precast') {
            const allPrecastNull = this.filteredPrecastSamples.every((sample) => sample.sampleStatusTypeId === null);
            if (allPrecastNull) {
              const errorMessage = messagesConfig.viewForm;
              return;
            }
          } else {
            // Check for Native samples
            const allNativeNull = this.filteredNativeSamples.every((sample) => sample.sampleStatusTypeId === null);
            if (allNativeNull) {
              const errorMessage = messagesConfig.viewForm;
              return;
            }
          }

          // Navigate to the appropriate route based on the flag status
          if (this.isFlagged) {
            this.router.navigate([`/flag/form/${this.soilType}/${projectID}`], navigationExtras);
          } else if (this.isSplitAction) {
            this.router.navigate([`/split/form/${this.soilType}/${this.projectId}`], navigationExtras);
          } else {
            this.router.navigate([`/review/form/${this.soilType}/${this.projectId}`], navigationExtras);
          }
        } else {
          console.log('No valid samples found in the data.');
        }
      },
      error: () => {
        // Handle error scenario
      },
    });
  }

  flagSubmission(projectID: string, soilType: string): void {
    // Set the isFlagged property to true
    this.isFlagged = true;
    // Store the flag status in session storage
    sessionStorage.setItem('submission-flagged', 'true');
    // Call the common API service to handle the flagging process

    this.commonFlagApiService(projectID, soilType);
  }

  onFlagSplitCancel() {
    const params = {
      f: 'd', // Query parameter to be passed during navigation
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
      // Other navigation options can be added here if needed
    };

    // Open a confirmation dialog
    this.pageLeaveDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'worksheet-dialog', // Custom CSS class for the dialog
      data: {
        title: 'Cancel Changes', // Title of the dialog
        content: 'Are you sure you want to cancel? Your Changes will not be saved!', // Content message of the dialog
        cancelText: 'NO, CONTINUE', // Text for the cancel button
      },
      disableClose: true, // Prevents closing the dialog by clicking outside of it
      enterAnimationDuration: '0ms', // Duration of the enter animation
      exitAnimationDuration: '0ms', // Duration of the exit animation
    });

    // Subscribe to the afterClosed observable to handle the response
    this.pageLeaveDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        // If the response is 'YES', remove the 'submission-flagged' item from session storage
        sessionStorage.removeItem('submission-flagged');
        sessionStorage.removeItem('split-submission');
        this.isFlagged = false; // Update the flag status
        this.isSplitAction = false;

        this.commonFlagApiService(this.projectId, this.soilType); // Call the common API service
      }
    });
  }

  splitAction(projectID:string, soilType: string) {
    this.isSplitAction = true;
    sessionStorage.setItem('split-submission', 'true');

    this.commonFlagApiService(projectID, soilType);
  }

  hanldeSplitProject(samples: Sample[]) {
    this.splitProject = samples;
  }

  onSplitWorksheet() {
    const { ...payload } = this.formData.company;
    this.formData.company = payload;

    if (this.formData.project) {
      this.formData.project.id = this.projectId || undefined;
    }
    if (payload.bId) {
      this.formData.billing.id = payload.bId;
    }

    const params = new HttpParams({
      fromObject: {
        f: 'd',
      },
    });
    if (this.projectId && this.isSplitAction) {
      if (this.companyInformation.projectNumber) {
        if (this.soilType === 'precast') {
          this.worksheetService.addPrecastSplitSubmissionWorksheet(this.projectId, this.splitProject).subscribe({
            next: (response) => {
              if (response) {
                const successMessage: IMessage = messagesConfig.splitSubmitUserSuccessMessage;
                const url = `/review/form/${this.soilType}/${this.projectId}?${params.toString()}`;
                this.showUpdatePopUpMessage(successMessage, url);
                sessionStorage.removeItem('submission-flagged');
                sessionStorage.removeItem('split-submission');
              }
            },
            error: () => {},
          });
        } else {
          this.worksheetService.addNativeSoilSplitSubmissionWorksheet(this.projectId, this.splitProject).subscribe({
            next: (response) => {
              if (response) {
                const successMessage: IMessage = messagesConfig.splitSubmitUserSuccessMessage;
                const url = `/review/form/${this.soilType}/${this.projectId}?${params.toString()}`;
                this.showUpdatePopUpMessage(successMessage, url);
                sessionStorage.removeItem('submission-flagged');
                sessionStorage.removeItem('split-submission');
              }
            },
            error: () => {},
          });
        }
      } else {
        const warningMessage: IMessage = messagesConfig.splitWarningMessage;
        this.showWarningPopUpMessage(warningMessage);
      }
    }
  }

  handleNoCheckboxChecked(isChecked: boolean) {
    this.isCheckboxChecked = isChecked;
  }

  handleDisbabledbtn(isEnabled:boolean) {
    this.splitEnabled = isEnabled;
    const disabledStatusIds = new Set([
      '8c84756d-37f3-4218-be67-ebcd351befc4',
      'b5a1472f-11b3-4d3c-a328-d084fc660720',
      'c8b24d68-c64c-4d3a-ba5d-c6bf7ceece99',
    ]);

    const projectStatusTypeId = this.formData?.project?.projectStatusTypeId;

    // Only check if projectStatusTypeId is defined
    if (projectStatusTypeId && disabledStatusIds.has(projectStatusTypeId)) {
      this.isMergeDisabled = false;
    } else {
      this.isMergeDisabled = true;
    }
    if ((this.formData.project?.isMerge ?? false) || (this.formData.samples?.length === 1)) {
      this.isMergeDisabled = true;
    }
  }
}
