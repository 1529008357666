import { Component, Input } from '@angular/core';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';

@Component({
  selector: 'app-native-sample',
  templateUrl: './native-sample.component.html',
  styleUrls: ['./native-sample.component.scss'],
})
export class NativeSampleComponent {
  @Input() previewData : REPORTPREVIEW;

  getBoldProject(content: string | undefined): string | undefined {
    if (content) {
     // return content.replace(this.previewData?.companyName, `<strong>${this.previewData?.companyName}</strong>`);
     let updatedContent = content.replace(this.previewData?.companyName, `<strong>${this.previewData?.companyName}</strong>`);
    
     // Bold "IEEE" wherever it appears in the content
     updatedContent = updatedContent.replace(/IEEE standard 442-2017/, `<strong>IEEE standard 442-2017</strong>`);
 
     return updatedContent;
    }
    return content;
  }
}
