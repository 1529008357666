import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/features/service/shared.service';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';

@Component({
  selector: 'app-precast-sample',
  templateUrl: './precast-sample.component.html',
  styleUrls: ['./precast-sample.component.scss'],
})
export class PrecastSampleComponent {
  @Input() previewData : REPORTPREVIEW;
  constructor(
   // private datePipe: DatePipe,
    private sharedService: SharedService,
  
  ) {}
  formatDate(dateString:string) {
    const parsedDate = new Date(dateString);
    const localDateString = this.sharedService.transform(parsedDate.toISOString());
    // Remove commas and return the formatted date (YYYY-MM-DD)
    return localDateString.replace(/,/g, '').split(' ')[0];
  }

  getBoldProject(content: string | undefined): string | undefined {
    if (content) {
     // return content.replace(this.previewData?.companyName, `<strong>${this.previewData?.companyName}</strong>`);
     let updatedContent = content.replace(this.previewData?.companyName, `<strong>${this.previewData?.companyName}</strong>`);
    
     // Bold "IEEE" wherever it appears in the content
     updatedContent = updatedContent.replace(/IEEE standard 442-2017/, `<strong>IEEE standard 442-2017</strong>`);
 
     return updatedContent;
    }
    return content;
  }
}
