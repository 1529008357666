import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ValidateOneDecimal(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const oneDecimalRegEx = /^(?!0$)(\d{1,3}|\.\d|\d{1,3}\.\d)$/g;
    if (control.value !== 0 && !oneDecimalRegEx.test(control.value)) {
      return {
        valid: false,
      };
    }
    return null;
  };
}

export function CustomDecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const regex = /^(?:0(?:\.\d{1,2})?|\d{1,4}(?:\.\d{1,2})?)$/;
    if (value && !regex.test(value)) {
      return {
        invalidFormat: true,
      };
    }
    return null;
  };
}

// This function are not allowed DOT[.] , DOT Zero [.0] and integer DOT[xxx.] else allows all the values even null
export function DecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const oneDecimalRegExFollowingNumber = /^(?!\.$)(?!\.0$)(?!.*\.$)(?!.*\.0$).*$|^$/;
    if (value && !oneDecimalRegExFollowingNumber.test(value)) {
      return {
        invalidFormat: true,
      };
    }
    return null;
  };
}

export function negativedecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (value === '-0') {
      return {
        invalidFormat: true,
      };
    }
    const oneDecimalRegExFollowingNumber = /^[-+]?\d*\.?\d+$/;
    if (value && !oneDecimalRegExFollowingNumber.test(value)) {
      return {
        invalidFormat: true,
      };
    }
    return null;
  };
}

export function DepthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.trim();

    if (!value) return null;

    // Regex for the decimal range format (e.g., 4.33-3.33, 1-2, etc.)
    const decimalRangePattern = /^[0-9]+(\.[0-9]{1,2})?-\d+(\.\d{1,2})?$/;

    // Regex for the "stockpile" word (case insensitive)
    const stockpilePattern = /^stockpile$/i;

    // Validate if it matches one of the allowed formats
    if (decimalRangePattern.test(value) || stockpilePattern.test(value)) {
      return null; // Valid
    }

    return {
      invalidDepthFormat: true,
    }; // Invalid format
  };
}
