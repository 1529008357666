<div class="col-md-12">
  <mat-checkbox
    [(ngModel)]="selectAllChecked"
    (change)="onSelectAllChange()"
    color="primary"
    class="checkbox-left"
    [indeterminate]="shouldShowIndeterminateState()">
    Select All Projects
  </mat-checkbox>
</div>

<div class="mergetable-wrapper">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let data of dataSource" >
      <mat-expansion-panel-header  >
        <mat-panel-title (click)="$event.stopPropagation();">
          <mat-checkbox [(ngModel)]="data.isChecked" color="primary" (change)="onCheckboxChange()"> </mat-checkbox>
          Project: {{ data.projectNumber }} - {{ data.projectName }} - {{ data.sampleCount }} Samples
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Parent Table -->
      <div class="parent-table">
        <table mat-table [dataSource]="[data]" class="mat-elevation-z2">
          <!-- Project Number Column -->
          <ng-container matColumnDef="projectNumber">
            <th mat-header-cell *matHeaderCellDef>Project #</th>
            <td mat-cell *matCellDef="let element">{{ element.projectNumber }}</td>
          </ng-container>

          <!-- Project Name Column -->
          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>Project Name</th>
            <td mat-cell *matCellDef="let element">{{ element.projectName }}</td>
          </ng-container>

          <!-- Soil Type Column -->
          <ng-container matColumnDef="soilType">
            <th mat-header-cell *matHeaderCellDef>Material Type</th>
            <td mat-cell *matCellDef="let element">{{ element.soilType }}</td>
          </ng-container>

          <!-- Submitted Column -->
          <ng-container matColumnDef="submitted">
            <th mat-header-cell *matHeaderCellDef>Submitted</th>
            <td mat-cell *matCellDef="let element">{{ element.submitted | date }}</td>
          </ng-container>

          <!-- Sample Received Date Column -->
          <ng-container matColumnDef="sampleReceivedDate">
            <th mat-header-cell *matHeaderCellDef>Received</th>
            <td mat-cell *matCellDef="let element">{{ element.sampleReceivedDate | date }}</td>
          </ng-container>

          <!-- Due Date Column -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>Due</th>
            <td mat-cell *matCellDef="let element">{{ element.dueDate | date }}</td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>

          <!-- Header and Rows -->
          <tr mat-header-row *matHeaderRowDef="parentDisplayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: parentDisplayColumns"></tr>
        </table>
      </div>

      <!-- Native Soil -->
      <div *ngIf="soiltype === 'nativesoil'">
      <div *ngIf="data.nativeSoilSamples && data.nativeSoilSamples.length > 0">
        <div class="child-table">
          <table mat-table [dataSource]="data.nativeSoilSamples" class="mat-elevation-z8">
            <!-- Define columns dynamically -->
            <ng-container *ngFor="let col of headerColumns" [matColumnDef]="col.name">
              <th mat-header-cell *matHeaderCellDef>
                <span *ngIf="col.name !== 'sampleStatusTypeId'">{{ col.displayName }}</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="col.name !== 'sampleStatusTypeId'">
                  <!-- Render displayDepth if applicable -->
                  <span *ngIf="col.name === 'displayDepth' && element[col.name] !== null">
                    {{ getDisplayDepth(element) }}
                  </span>
                  <!-- Render other columns -->
                  <span
                    [title]="col.format === 'truncate' && element[col.name] ? element[col.name] : ''"
                    *ngIf="col.name !== 'displayDepth' && element[col.name] !== null"
                    [innerHTML]="(element[col.name] ? element[col.name] : '') | format : col.format">
                  </span>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div></div>

      <!-- Precast -->
      <div *ngIf="soiltype === 'precast'">
      <div *ngIf="data.preCastSamples && data.preCastSamples.length > 0">
        <div class="child-table">
          <table mat-table [dataSource]="data.preCastSamples" class="mat-elevation-z8">
            <!-- Define columns dynamically -->
            <ng-container *ngFor="let col of headerColumns" [matColumnDef]="col.name">
              <th mat-header-cell *matHeaderCellDef>
                <span *ngIf="col.name !== 'sampleStatusTypeId'">{{ col.displayName }}</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="col.name !== 'sampleStatusTypeId'">
                  <!-- Render displayDepth if applicable -->
                  <span *ngIf="col.name === 'displayDepth' && element[col.name] !== null">
                    {{ getDisplayDepth(element) }}
                  </span>
                  <!-- Render other columns -->
                  <span
                    [title]="col.format === 'truncate' && element[col.name] ? element[col.name] : ''"
                    *ngIf="col.name !== 'displayDepth' && element[col.name] !== null"
                    [innerHTML]="(element[col.name] ? element[col.name] : '') | format : col.format">
                  </span>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div></div>

    </mat-expansion-panel>
  </mat-accordion>
</div>
