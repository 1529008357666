<div class="r-company-wrapper">
    <mat-accordion *ngIf="data">
      <mat-expansion-panel (opened)="onCompanyPanelOpened()" (closed)="onCompanyPanelClosed()">
        <mat-expansion-panel-header class="header-panel">
          <mat-panel-title class="title-panel">
            <h2 class="header-title">Company Name: {{data.name}}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-container">
          <div class="item">
            <div class="text">
              <div class="label">Company Name</div>
              <div class="name">{{data.name}}</div>
            </div>
          </div>
          <div class="item">
            <div class="text">
              <div class="label">Contact Name</div>
              <div class="name">{{data.contactName}}</div>
            </div>
          </div>
          <div class="item">
            <div class="text">
              <div class="label">Contact Email - Reports Submittal</div>
              <div class="name">{{data.emails}}</div>
            </div>
          </div>
          <div class="item">
            <div class="text">
              <div class="label">Address</div>
              <div class="name">{{data.streetAddress}}</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>