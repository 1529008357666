import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MultipleEmailValidator } from 'src/app/shared/validators/multiple-email.validator';
import { debounceTime, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { UserService } from '../../../../shared/services/user.service';
import { ICompanyData, ICompanyDetails } from '../../../../shared/models/i-company.modal';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss'],
})
export class CompanyInformationComponent implements OnInit, OnChanges {
  @Output() companyData: EventEmitter<ICompanyData> = new EventEmitter();
  @Input() submissionType: string;
  @Input() data: any;
  makeSampleEditable = true;
  @Input() set makeEditable(value: boolean) {
    this.makeSampleEditable = value;
  }

  initialLoad = true;
  companyFormGroup: FormGroup;
  rushToggleDisabled: boolean;
  companies: ICompanyDetails[];
  filteredCompanies: Observable<ICompanyDetails[]>;
  name = new FormControl();
  isUserLoggedin = false;
  isCompanyReadonly = false;
  public companyName: any = '';
  countries: any;
  bcountries: any;
  states: any;
  bstates:any;
  cities: any;
  bcities: any;
  searchCompanyInput = '';
  copyControlField = [
    {
      control: 'contactName', cyControl: 'bContactName',
    },
    {
      control: 'emails', cyControl: 'bContactEmail',
    },
    {
      control: 'contactNumber', cyControl: 'bcontactNumber',
    },
    {
      control: 'streetAddress', cyControl: 'bstreetAddress',
    },
    {
      control: 'postalCode', cyControl: 'bpostalCode',
    },
    {
      control: 'countryId', cyControl: 'bcountryId',
    },
    {
      control: 'stateId', cyControl: 'bstateId',
    },
    {
      control: 'cityId', cyControl: 'bcityId',
    },
    {
      control: 'name', cyControl: 'invoice',
    },
  ];

  private fetchCompanySubject = new Subject<string>();
  constructor(
    private fb: FormBuilder,
    private staticDataService: LookupService,
    private dashboardService: PrecastService,
    public dialog: MatDialog,
    public userService: UserService,
  ) {
    this.userService.userAccount.subscribe((account) => {
      if (account) {
        this.isUserLoggedin = true;
      } else {
        this.isUserLoggedin = false;
      }
    });
    this.companyFormGroup = this.fb.group({
      companyId: [''],
      projectId: [''],
      bId: [''],
      name: ['', Validators.required],
      contactNumber: ['', Validators.required],
      emails: ['', [Validators.required, MultipleEmailValidator.comaSeperatedEmailValidator()]],
      contactName: ['', Validators.required],
      streetAddress: [''],
      countryId: [''],
      stateId: [''],
      cityId: [''],
      postalCode: [''],
      reportSubmissionDate: [''],
      hasSameBilling: [false],
      invoice: [''],
      bcontactNumber: [''],
      bemails: ['', MultipleEmailValidator.comaSeperatedEmailValidator()],
      bstreetAddress: [''],
      bcountryId: [''],
      bstateId: [''],
      bcityId: [''],
      bpostalCode: [''],
      projectName: ['', Validators.required],
      projectLocation: [''],
      jobNumber: [''],
      projectNumber: [''],
      geothermField: [
        {
          value: false,
          disabled: !this.makeSampleEditable,
        },
      ],
      instructionsConfirmed: [false],
      rushTesting: [false],
      preApproval: [
        {
          value: false,
          disabled: !this.makeSampleEditable,
        },
      ],
      projectStatusTypeId: [null],
      bContactName: ['', Validators.required],
      bContactEmail: ['', [Validators.required, MultipleEmailValidator.comaSeperatedEmailValidator()]],
      // dueDate: [''],
      inHouseIdPrefix: ['', [Validators.pattern('^[A-Z]{0,2}$')]],
    });
    // this.filteredCompanies = this.name.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(company => company ? this._filterStates(company) : this.companies.slice())
    //   );
  }

  ngOnInit(): void {
    this.fetchCountries();
    if (this.data && this.data?.companyId) {
      this.isCompanyReadonly = true;
    }
    if (this.data) {
      this.getCompanyState(this.data?.countryId);
      this.getCompanyCities(this.data?.stateId);
      this.getBillingStates(this.data?.bcountryId);
      this.getBillingCities(this.data?.bstateId);

      this.rushToggleDisabled = this.data.isRushToggleEnabled;
      if (this.data.contactNumber) {
        this.data.contactNumber = this?.data?.contactNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      }

      if (this.data.bcontactNumber) {
        this.data.bcontactNumber = this?.data?.bcontactNumber
          .slice(0, 10)
          .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      }
      this.companyFormGroup.patchValue(this.data);
      this.checkHasBillingInfo();
      this.companyFormGroup.markAsDirty();
    }
    this.companyFormGroup?.valueChanges?.subscribe((frmGroup) => {
      let validForm = this.companyFormGroup?.valid;
      // checking null value
      const v = this.companyFormGroup?.value?.contactNumber
        ? this.companyFormGroup.value.contactNumber.replace(/[^0-9]/g, '')
        : '';

      if (v.length < 10) {
        this.companyFormGroup?.controls['contactNumber'].setErrors({
          validContactNumber: false,
        });
        validForm = false;
      }
      // checking null value
      const bv = this.companyFormGroup?.value?.bcontactNumber
        ? this.companyFormGroup.value.bcontactNumber.replace(/[^0-9]/g, '')
        : '';
      if (this.companyFormGroup.value.bcontactNumber) {
        if (bv.length > 1 && bv.length < 10) {
          this.companyFormGroup?.controls['bcontactNumber'].setErrors({
            validbContactNumber: false,
          });
          validForm = false;
        }
      }
      this.companyData?.emit({
        value: frmGroup,
        dirty: this.companyFormGroup?.dirty,
        valid: validForm,
      });
    });
    this.setFormControlState();
    this.searchCompanyInput = this.companyFormGroup?.controls['name'].value;

    this.fetchCompanySubject
      .pipe(
        debounceTime(300), // Add a 300ms delay
        switchMap((searchCompanyName: string) => this.dashboardService?.fetchCompanyDetail(searchCompanyName)),
      )
      .subscribe({
        next: (response: ICompanyDetails) => {
          this.companies = Object.assign([], response);
          this.filteredCompanies = this.companyFormGroup.controls['name'].valueChanges.pipe(
            startWith(''),
            map((value) => this.filterStates(value || '')),
          );
        },
      });

    this.initialLoad = false;
  }

  fetchCountries(): void {
    this.staticDataService?.fetchCountries()?.subscribe({
      next: (response) => {
        this.countries = response;
        this.bcountries = response;
      },
    });
  }

  getCompanyState(countryId: string) {
    if (Number(countryId) > 0) {
      this.staticDataService?.fetchState(countryId)?.subscribe({
        next: (response) => {
          this.states = response;
          const hasSameBilling = this.data?.hasSameBilling || this.getControl('hasSameBilling')?.value;
          this.getControl('stateId').patchValue(null);
          this.getControl('cityId').patchValue(null);
          if (hasSameBilling) {
            this.bstates = response;
            this.checkCopyEnabled('countryId', 'bcountryId');
            this.checkCopyEnabled('stateId', 'bstateId');
            this.checkCityCopy();
          }
        },
      });
      this.cities = [];
    } else {
      this.states = [];
      this.cities = [];
      this.getControl('stateId').patchValue(null);
      this.getControl('cityId').patchValue(null);
      const hasSameBilling = this.data?.hasSameBilling || this.getControl('hasSameBilling')?.value;
      if (hasSameBilling) {
        this.bstates = [];
        this.bcities = [];
        this.getControl('bcountryId').patchValue(null);
        this.getControl('bstateId').patchValue(null);
        this.getControl('bcityId').patchValue(null);
      }
    }
  }

  getCompanyCities(cityId : string) {
    if (Number(cityId) > 0) {
      this.staticDataService?.fetchCities(cityId)?.subscribe({
        next: (response) => {
          this.getControl('cityId').patchValue(null);
          this.cities = response;
          const hasSameBilling = this.data?.hasSameBilling || this.getControl('hasSameBilling')?.value;
          if (hasSameBilling) {
            this.bcities = response;
            this.checkCopyEnabled('stateId', 'bstateId');
            this.checkCityCopy();
          }
        },
      });
    } else {
      this.getControl('cityId').patchValue(null);
      this.cities = [];
      const hasSameBilling = this.data?.hasSameBilling || this.getControl('hasSameBilling')?.value;
      if (hasSameBilling) {
        this.getControl('bcityId').patchValue(null);
        this.getControl('bstateId').patchValue(null);
      }
    }
  }

  getBillingStates(countryId: string) {
    if (Number(countryId) > 0) {
      this.staticDataService?.fetchState(countryId)?.subscribe({
        next: (response) => {
          this.getControl('bstateId').patchValue(null);
          this.bstates = response;
        },
      });
      this.bcities = [];
    } else {
      this.bstates = [];
      this.getControl('bstateId').patchValue(null);
      this.getControl('bcityId').patchValue(null);
    }
  }

  getBillingCities(stateId: string) {
    if (Number(stateId) > 0) {
      this.staticDataService?.fetchCities(stateId)?.subscribe({
        next: (response) => {
          this.getControl('bcityId').patchValue(null);
          this.bcities = response;
        },
      });
    } else {
      this.bcities = [];
      this.getControl('bcityId').patchValue(null);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['makeEditable']) {
      this.setFormControlState();
    }
  }

  setFormControlState(): void {
    const geothermFieldControl = this.companyFormGroup.get('geothermField') as FormControl;
    const preApprovalControl = this.companyFormGroup.get('preApproval') as FormControl;
    // const rushTestingControl = this.companyFormGroup.get('rushTesting') as FormControl;

    if (this.makeSampleEditable) {
      geothermFieldControl.enable();
      preApprovalControl.enable();
    } else {
      geothermFieldControl.disable();
      preApprovalControl.disable();
    }
    // if (this.makeSampleEditable) {
    //   rushTestingControl.enable();
    // } else {
    //   rushTestingControl.disable();
    // }

    geothermFieldControl.updateValueAndValidity();
    preApprovalControl.updateValueAndValidity();
    // rushTestingControl.updateValueAndValidity();
  }

  get emailsError(): any {
    return this.companyFormGroup?.controls['emails']?.errors
      ? this.companyFormGroup?.controls['emails']?.errors
      : false;
  }

  get bemailsError(): any {
    return this.companyFormGroup?.controls['bemails']?.errors
      ? this.companyFormGroup?.controls['bemails']?.errors
      : false;
  }

  get contactEmailsError(): any {
    return this.companyFormGroup?.controls['bContactEmail']?.errors
      ? this.companyFormGroup?.controls['bContactEmail']?.errors
      : false;
  }

  get contactNumberErrors(): any {
    return this.companyFormGroup?.controls['contactNumber']?.errors
      ? this.companyFormGroup?.controls['contactNumber']?.errors
      : false;
  }

  get bcontactNumberErrors(): any {
    return this.companyFormGroup?.controls['bcontactNumber']?.errors
      ? this.companyFormGroup?.controls['bcontactNumber']?.errors
      : false;
  }

  setCopy(): void {
    const copyControl = [...this.copyControlField];
    copyControl.forEach((control: any) => {
      this.copyControl(control.control, control.cyControl);
    });
  }

  setControlDisableOnSameASAbove(): void {
    const copyControl = [...this.copyControlField];
    copyControl.forEach((control: any) => {
      if (this.getControl(control.cyControl)) {
        this.getControl(control.cyControl).disable();
        this.getControl(control.cyControl).updateValueAndValidity();
      }
    });
  }

  setControlEnableOnSameASAbove(): void {
    const copyControl = [...this.copyControlField];
    copyControl.forEach((control: any) => {
      if (this.getControl(control.cyControl)) {
        this.getControl(control.cyControl).enable();
        this.getControl(control.cyControl).updateValueAndValidity();
      }
    });
  }

  checkCopyEnabled(controlName:string, copyControlName:string): void {
    if (this.getControl('hasSameBilling').value) {
      this.copyControl(controlName, copyControlName);
    }
  }

  getControl(controlName: string): FormControl {
    return this.companyFormGroup?.get(controlName) as FormControl;
  }

  copyControl(controlName: string, copyControlName: string): void {
    if (this.getControl(controlName) && this.getControl(copyControlName)) {
      this.getControl(copyControlName).patchValue(this.getControl(controlName).value);
      this.getControl(copyControlName).updateValueAndValidity();
    }
  }

  checkCityCopy(): void {
    this.checkCopyEnabled('cityId', 'bcityId');
  }

  checkHasBillingInfo(): void {
    const check = this.companyFormGroup?.controls['hasSameBilling']?.value;
    if (check) {
      this.bcountries = this.countries;
      this.bstates = this.states;
      this.bcities = this.cities;
      this.setCopy();
      this.setControlDisableOnSameASAbove();
    } else {
      this.setControlEnableOnSameASAbove();
    }
  }

  clearBillingInfo(): void {
    this.companyFormGroup?.controls['invoice']?.setValue('');
    this.companyFormGroup?.controls['bemails']?.setValue('');
    this.companyFormGroup?.controls['bcontactNumber']?.setValue('');
    this.companyFormGroup?.controls['bContactName']?.setValue('');
    this.companyFormGroup?.controls['bContactEmail']?.setValue('');
    this.companyFormGroup?.controls['bstreetAddress']?.setValue('');
    this.companyFormGroup?.controls['bcountryId']?.setValue('');
    this.companyFormGroup?.controls['bcityId']?.setValue('');
    this.companyFormGroup?.controls['bstateId']?.setValue('');
    this.companyFormGroup?.controls['bpostalCode']?.setValue('');
  }

  fetchCompanyDetails(searchCompanyName: string) {
    this.fetchCompanySubject.next(searchCompanyName);
  }

  getCompanyDetail() {
    const selected = this.getControl('name').value;
    if (selected && !this.isCompanyReadonly) {
      this.fetchCompanyDetails(selected);
    }
  }

  private filterStates(value: string): ICompanyDetails[] {
    const filterValue = value.toLowerCase();
    // Filter companies based on the input value
    return this.companies.filter((company) => company.name.toLowerCase().includes(filterValue));
  }

  onOptionSelected(event: any): void {
    this.companyFormGroup.controls['companyId'].patchValue(event.option.id);
    this.companyFormGroup.controls['name'].patchValue(event.option.value);
    this.companyFormGroup.controls['companyId'].updateValueAndValidity();
    this.checkCopyEnabled('name', 'invoice');
    this.companyFormGroup.controls['name'].updateValueAndValidity();

    this.isCompanyReadonly = true;
  }

  removeExitsingCompany(): boolean {
    this.companyFormGroup.controls['companyId'].patchValue(null);
    this.companyFormGroup.controls['name'].patchValue('');
    this.companyFormGroup.controls['companyId'].updateValueAndValidity();
    this.companyFormGroup.controls['name'].updateValueAndValidity();
    this.searchCompanyInput = '';
    this.isCompanyReadonly = false;
    this.filteredCompanies = of([]);
    this.checkCopyEnabled('name', 'invoice');
    return false;
  }

  companyFocusOut(): void {
    this.getControl('name').patchValue(this.searchCompanyInput);
  }
}
