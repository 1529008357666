<div class="gcontainer bwhite">
  <div class="row">
    <div class="col-md-8">
      <nav aria-label="breadcrumb" class="mt-8">
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item active breadcrumb cursor"
            aria-current="page"
            (click)="reDirectUrl()"
            (keypress)="reDirectUrl()"
            tabindex="0">
            Dashboard
          </li>
          <li class="breadcrumb-item active" aria-current="page" *ngIf="worksheetRecords">
            {{ worksheetRecords.company.name }} -  {{ worksheetRecords.project.projectName }} - Worksheet -
            <span *ngIf="soilType === 'precast'">Pre Cast</span>
            <span *ngIf="soilType === 'nativesoil'">Native Soil</span>
          </li>
        </ol>
      </nav>
    </div>

    <div class="col-md-2" align="right">
      <button class="refresh" mat-raised-button (click)="refresh()" >        
      Refresh
    </button>
    </div>
   
    <div class="col-md-1" align="right">
      
      <button mat-raised-button (click)="export()" > 
        <img src="/assets/icons/Print-red.svg" class="export-print-btn" alt="print" />PRINT</button>
    </div>
    <div class="col-md-1" align="right">
      <button
        [matMenuTriggerFor]="aboveMenu"
        mat-raised-button
        color="primary"
        *ngIf="this.userService.roleCheck(['Manager', 'Admin', 'Reportwriter'])">
        ACTIONS
      </button>
      <mat-menu class="dashboard-menu menu-border" #aboveMenu="matMenu" yPosition="below">
        <button
          mat-menu-item
          (click)="review(projectId, soilType)"
          *ngIf="this.userService.roleCheck(['Manager', 'Admin', 'Reportwriter'])">
          <span class="menu-text">
            <img src="/assets/icons/File.svg" alt="File" />
            View Submission Form
          </span>
        </button>
      </mat-menu>
    </div>
        <div class="col-md-12 mt-16" *ngIf="setRefresh">
          <mat-card class="bsmokewhite">
            <mat-card-content>
              <div class="row">
                <div class="col-md-11">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mat-h1">
                            <span *ngIf="soilType === 'precast'">Pre Cast Worksheet</span>
                            <span *ngIf="soilType === 'nativesoil'">Native Soil Worksheet</span>
                          </h1>
                        </div>
                        <div class="col-4">
                          <mat-chip class="chipset-green float-right" *ngIf="isGeoTesting">
                            <img
                              src="./../../../assets/icons/pre-approval-tick.svg"
                              class="icon-position"
                              alt="Rush-Testing-Warning" />
                            <span class="text-white chip-text">Geotherm Field Testing</span>
                          </mat-chip>
                          <mat-chip class="chipset-blue float-right" *ngIf="soilType === 'precast' && preApprovalTesting">
                            <img
                              src="./../../../assets/icons/pre-approval-tick.svg"
                              class="icon-position"
                              alt="Rush-Testing-Warning" />
                            <span class="text-white chip-text"> Pre-Approval</span>
                          </mat-chip>
                          <mat-chip class="chipset-warning float-right" *ngIf="isRushTesting">
                            <img
                              src="./../../../assets/icons/rush-warning-icon.svg"
                              class="icon-position"
                              alt="Rush-Testing-Warning" />
                            <span class="chip-text"> Rush</span>
                          </mat-chip>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="mat-subtitle-2">Customer</mat-label>
                      <div class="pr-32">
                        <span
                          class="mat-subtitle-1 truncate-label-2line"
                          #tooltip="matTooltip"
                          *ngIf="worksheetRecords"
                          [matTooltip]="worksheetRecords.company.name">
                          {{ worksheetRecords.company.name }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <mat-label class="mat-subtitle-2">Project Name</mat-label>
                      <div class="pr-32">
                        <span
                          class="mat-subtitle-1 truncate-label-2line"
                          #tooltip="matTooltip"
                          *ngIf="worksheetRecords"
                          [matTooltip]="worksheetRecords.project.projectName">
                          {{ worksheetRecords.project.projectName }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div>
                        <mat-label class="mat-subtitle-2">Project Number</mat-label>
                        <div class="pr-32">
                          <span
                            class="mat-subtitle-1 truncate-label-2line"
                            #tooltip="matTooltip"
                            *ngIf="worksheetRecords"
                            [matTooltip]="worksheetRecords.project.projectNumber">
                            {{ worksheetRecords.project.projectNumber }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div>
                        <mat-label class="mat-subtitle-2">Samples Received</mat-label>
                        <div>
                          <span class="mat-subtitle-1 truncate-label" *ngIf="sampleReceivedDate">
                            {{ sampleReceivedDate | utcToLocal: 'date' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div>
                        <mat-label class="mat-subtitle-2">Instructions Confirmed</mat-label>
                        <div>
                          <span class="mat-subtitle-1 truncate-label" *ngIf="instructionConfirmedAt">
                            {{ instructionConfirmedAt | utcToLocal: 'date' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div>
                        <mat-label class="mat-subtitle-2">Due Date</mat-label>
                        <div>
                          <span class="mat-subtitle-1 truncate-label" *ngIf="dueDate">
                            {{ dueDate | utcToLocal: 'date' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <app-customer-attachments
                        [projectId]="projectId"
                        [backgroundClass]="'gray'"
                        [showSuccess]="false"
                        style="vertical-align: middle; margin-right: 16px"></app-customer-attachments>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="no-of-days na-days-left" *ngIf="daysleft === null">NA</div>
                  <div class="no-of-days" *ngIf="daysleft && daysleft > 0">{{ daysleft }} days</div>
                  <div class="no-of-days critical-days-left" *ngIf="daysleft === 0 || daysleft && daysleft < 1">0 days</div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-5 mt-16"  *ngIf="setRefresh">
          <div class="notes-wrapper">
            <div *ngIf="note">
              <mat-label class="mat-subtitle-2 bold-title">
                <span *ngIf="note.updatedBy" class="d-inline"
                  >{{ note.updatedBy.firstName }} {{ note.updatedBy.lastName }}:</span
                >
                <span *ngIf="note.createdAt" class="d-inline">
                  {{ note.createdAt | utcToLocal: 'date' }} {{ note.createdAt | utcToLocal: 'time' }}
                </span>
              </mat-label>
              <div>
                <span
                  class="mat-subtitle-2"
                  matTooltipClass="my-custom-tooltip"
                  #tooltip="matTooltip"
                  [matTooltip]="note.text">
                  {{ noteText }}
                </span>
              </div>
            </div>
            <div *ngIf="!note" style="min-height: 60px">
              <p class="truncate-label">No Notes Available.</p>
            </div>
          </div>
          <div>
            <a class="view-all" (click)="openViewNotesDialog()" href="javascript:void(0);">
              <img alt="View Notes" src="../../../../assets/icons/New Window.svg" class="view-note" />
              View All Notes
            </a>
          </div>
        </div>
        <div class="col-md-7 mt-16"  *ngIf="setRefresh">
          <div class="row">
            <div class="col-md-4 pr-12">
              <mat-card class="project-details-card">
                <span class="project-test-data-title"
                  >Oven Date
                  <a
                    *ngIf="!isReportWriter"
                    class="float-right"
                    (click)="openProjectDetailsComponent('Oven Date', 'ovenDate')"
                    href="javascript:void(0)">
                    <img alt="info Icon" class="star-mar-top" src="../../../../assets/icons/Edit.svg" />
                  </a>
                </span>
                <span class="mat-subtitle-1 line-clamp text-word-break" #tooltip="matTooltip" matTooltip="{{ ovenDate }}">
                  {{ ovenDate }}
                </span>
              </mat-card>
            </div>
            <div class="col-md-4 pr-12">
              <mat-card class="project-details-card">
                <span class="project-test-data-title">
                  Wet Test Data
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip='Separate values by a "," comma'
                    src="../../../../assets/icons/Alert-grey.svg" />
                  <a
                    *ngIf="!isReportWriter"
                    class="float-right"
                    (click)="openProjectDetailsComponent('Wet Test Data', 'wetTestData')"
                    href="javascript:void(0)">
                    <img alt="info Icon" class="star-mar-top" src="../../../../assets/icons/Edit.svg" />
                  </a>
                </span>
                <span
                  class="mat-subtitle-1 line-clamp text-word-break"
                  #tooltip="matTooltip"
                  matTooltip="{{ wetTestData }}">
                  {{ wetTestData }}
                </span>
              </mat-card>
            </div>
            <div class="col-md-4 pr-12">
              <mat-card class="project-details-card">
                <span class="project-test-data-title"
                  >Dry Test Data
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip='Separate values by a "," comma'
                    src="../../../../assets/icons/Alert-grey.svg" />
                  <a
                    *ngIf="!isReportWriter"
                    class="float-right"
                    (click)="openProjectDetailsComponent('Dry Test Data', 'dryTestData')"
                    href="javascript:void(0)">
                    <img alt="info Icon" class="star-mar-top" src="../../../../assets/icons/Edit.svg" />
                  </a>
                </span>
                <span
                  class="mat-subtitle-1 line-clamp text-word-break"
                  #tooltip="matTooltip"
                  matTooltip="{{ dryTestData }}">
                  {{ dryTestData }}
                </span>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-16"  *ngIf="setRefresh">
          <app-precast-table
            (assigned)="assignedDone()"
            [projectId]="projectId"
            *ngIf="soilType === 'precast'"
            (allResPrecastWorksheet)="handleAllResPrecastWorksheet($event)"
            (headerPrecastWorksheet)="handleFetchPrecastHeader($event)"
            (rowDeletionSuccess)="onRowDeletionSuccess()"></app-precast-table>
          <app-nativesoil-table
            (assigned)="assignedDone()"
            [projectId]="projectId"
            [instructionConfirmed] = "instructionsConfirmed"
            *ngIf="soilType === 'nativesoil'"
            (fetchAllHeader)="handleFetchAllHeader($event)"
            (allResNativeWorksheet)="handleAllResNativeWorksheet($event)"
            (rowDeletionSuccess)="onRowDeletionSuccess()"></app-nativesoil-table>
        </div>
    </div>
  </div>