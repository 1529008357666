import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ICompany } from 'src/app/shared/models/i-company.modal';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnChanges{
 
  @Input() data: ICompany;
  companyPanelOpenState = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      // Handle changes to the `data` input, if needed
    }
  }
  
  onCompanyPanelOpened() {
    this.companyPanelOpenState = true;
  }

  onCompanyPanelClosed() {
    this.companyPanelOpenState = false;
  }
}
