<div class="gcontainer bwhite" *ngIf="userAccount">
  <div class="row">
    <div class="col-md-4">
      <h1 class="mat-h1">Project Dashboard</h1>
      <form (submit)="pageNavigate('keyWordSearch')" class="search-section">
        <mat-form-field appearance="outline">
          <input matInput placeholder="Search" class="search-text" maxlength="150" (input)="onSearchChange()"
            (keypress)="onKeyPress($event)" [(ngModel)]="searchKeyWord" [ngModelOptions]="{ standalone: true }" />
          <mat-icon *ngIf="searchKeyWord.length === 0" class="cursor" matSuffix>search</mat-icon>
          <mat-icon *ngIf="searchKeyWord.length > 0" class="cursor" matSuffix (click)="onClearSearchButtonClick()">
            close
          </mat-icon>
        </mat-form-field>
      </form>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-2 pt-16" *ngIf="!userService.roleCheck(['Manager', 'Admin'])"></div>
        <div class="col-md-5 pt-16 pl-16">
          <mat-card class="shadow-none">
            <mat-card-content>
              <div class="soil-type">
                <span class="mat-subtitle-2 bold-title">Native Soil</span>
              </div>
              <app-project-count [projectCount]="projectsCount.nativeSoil"></app-project-count>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-5 pt-16 pl-16">
          <mat-card class="shadow-none">
            <mat-card-content>
              <div class="soil-type">
                <span class="mat-subtitle-2 bold-title">Pre Cast</span>
              </div>
              <app-project-count [projectCount]="projectsCount.preCast"></app-project-count>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-2 pt-16 pl-16" *ngIf="userService.roleCheck(['Manager', 'Admin'])">
          <mat-card class="align-add-project shadow-none">
            <mat-card-content>
              <a (click)="routingPage()" href="javascript:void(0);">
                <img class="dashboard-icon" src="/assets/icons/plus-icon.svg" alt="add-project-icon" />
              </a>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <!-- Tab Starts -->
  <div class="tab-container mar-tp">
    <div class="row" *ngIf="projectRecords.length > 0">
      <div class="col-md-5">
        <span *ngIf="showSearchResultCount" class="search-results-count">
          {{ totalRecords }} result(s) for "{{ searchKeyWord }}"
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <mat-checkbox [checked]="mixIdCheckboxValue" [(ngModel)]="mixIdCheckboxValue" color="primary"
          (click)="$event.stopPropagation()" (change)="isSearchInMixId($event)" [disabled]="isMixIdCheckboxDisabled">
          Include Mix ID
        </mat-checkbox>
      </div>
      <div class="col-md-2">
        <mat-checkbox [checked]="inActiveRecordsCheckboxValue" [(ngModel)]="inActiveRecordsCheckboxValue"
          *ngIf="userService.roleCheck(['Admin','Manager', 'Reportwriter'])" color="primary"
          (click)="$event.stopPropagation()" (change)="includeInactiveRecords($event)" [disabled]="isCheckboxDisabled">
          Include Inactive records
        </mat-checkbox>
      </div>
      <div class="col-md-8">
        <div class="container-for-clearFilter">
          <a href="javascript:void(0);" (click)="clearFilters()" class="clear-filter">Clear Filter(s)</a>
        </div>
      </div>
    </div>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #matgroup animationDuration="0ms"
      [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="ALL PROJECTS">
        <ng-container *ngTemplateOutlet="dashboardTableContent"></ng-container>
      </mat-tab>
      <mat-tab [label]="
          this.userService.roleCheck(['Labtech', 'Manager', 'Reportwriter']) ? 'MY PROJECTS' : 'REPORTS TO REVIEW'
        ">
        <ng-container *ngTemplateOutlet="dashboardTableContent"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ng-template #dashboardTableContent>
  <section class="dash-container">
    <mat-divider class="pb-24"></mat-divider>
    <mat-table #table *ngIf="
        projectRecords.length > 0 ||
        materialType ||
        projectStatus ||
        searchKeyWord ||
        selectedMaterialTypes ||
        selectedProjectStatus
      " aria-describedby="Projects list" mat-table matSortDisableClear [dataSource]="dataSource" matSort
      (matSortChange)="sortData($event)" class="mat-table">
      <mat-header-row *matHeaderRowDef="displayedColumn.concat('action'); sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumn.concat('action')"></mat-row>

      <ng-container *ngFor="let columnName of displayedColumn" [matColumnDef]="columnName">
        <mat-header-cell class="header-text" *matHeaderCellDef mat-sort-header
          [disabled]="columnName === 'soilType' || columnName === 'status' || columnName === 'assignedUser' || columnName === 'isSplit'"
          [matTooltip]="getTooltipText(columnName)">
          <!--Material Type column -->
          <ng-container *ngIf="columnName === 'soilType'" class="material-width">
            {{ getDisplayName(columnName) }}
            <a href="javascript:void(0)" [matMenuTriggerFor]="materialType" #menuTrigger="matMenuTrigger"
              class="cursor icon-float">
              <img src="/assets/icons/Filter.svg" class="icon-mt-1" alt="alert-icon" /> </a><mat-menu
              class="dashboard-menu mat-menu-box" #materialType="matMenu" yPosition="below" xPosition="before">
              <mat-list (click)="$event.stopPropagation()">
                <mat-list-item *ngFor="let materialType of materialTypes">
                  <mat-checkbox color="primary" [value]="materialType.id"
                    [checked]="selectedMaterialTypes.includes(materialType.label)" (change)="
                      onCheckboxClick(
                        $event.checked,
                        materialType.label,
                        selectedMaterialTypes,
                        materialTypes,
                        'materialType'
                      )
                    " (click)="$event.stopPropagation()" *ngIf="!materialType.disabled">
                    {{ materialType.label }}
                  </mat-checkbox>
                </mat-list-item>
                <button mat-button class="btn-action btn-update"
                  (click)="pageNavigate('materialTypeCheck'); menuTrigger.closeMenu()">
                  UPDATE
                </button>
              </mat-list>
            </mat-menu>
          </ng-container>
          <!--Status Column -->
          <ng-container *ngIf="columnName === 'status'">
            {{ getDisplayName(columnName) }}
            <a href="javascript:void(0)" [matMenuTriggerFor]="statusFilter" #menuTrigger="matMenuTrigger"
              class="cursor icon-float">
              <img src="/assets/icons/Filter.svg" class="icon-mt-1" alt="alert-icon"
                *ngIf="!(this.userService.roleCheck(['Reportwriter']) && this.myProjects)" /> </a><mat-menu
              class="dashboard-menu mat-menu-box" #statusFilter="matMenu" (click)="$event.stopPropagation()"
              yPosition="below" xPosition="before" (click)="$event.stopPropagation()">
              <mat-list (click)="$event.stopPropagation()">
                <mat-list-item *ngFor="let projectStatus of projectStatuses">
                  <mat-checkbox color="primary" [value]="projectStatus.id"
                    [checked]="selectedProjectStatus.includes(projectStatus.label)" (change)="
                      onCheckboxClick(
                        $event.checked,
                        projectStatus.label,
                        selectedProjectStatus,
                        projectStatuses,
                        'projectStatus'
                      )
                    " (click)="$event.stopPropagation()">{{ projectStatus.label }}</mat-checkbox>
                </mat-list-item>

                <button #triggerBtn mat-button class="btn-action btn-update"
                  (click)="pageNavigate('projectStatusCheck'); menuTrigger.closeMenu()">
                  UPDATE
                </button>
              </mat-list>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="columnName !== 'soilType' && columnName !== 'status'">
            {{ getDisplayName(columnName) }}
          </ng-container>
        </mat-header-cell>

        <mat-cell *matCellDef="let row" class="ellips">
          <!-- Display  content for 'materialtype' column -->
          <ng-container *ngIf="columnName === 'soilType'">
            {{ row.soilType }}
          </ng-container>
          <!-- Display  content for 'status' column -->
          <ng-container *ngIf="columnName === 'status'">
            {{ row.status }}
          </ng-container>
          <!-- Display  content for 'rush' column -->
          <ng-container *ngIf="columnName === 'rushed'">
            <span class="mar-tp-5" *ngIf="row.rushed === true">
              <img src="/assets/icons/Rush.svg" alt="alert-icon" />
            </span>
          </ng-container>

          <ng-container *ngIf="columnName === 'isSplit'">
            <span class="mar-tp-5" *ngIf="row.isSplit === true && row.isMerge === false">
              <img src="/assets/icons/chain_link.svg" class="split" alt="alert-icon" />
            </span>
          </ng-container>
          <ng-container *ngIf="columnName === 'isSplit'">
            <span class="mar-tp-5" *ngIf="row.isMerge === true">
              <img src="/assets/icons/Merge.svg" class="split" alt="alert-icon" />
            </span>
          </ng-container>

          <ng-container *ngIf="columnName === 'geothermField'">
            <span class="mar-tp-5 geotherm-field" *ngIf="row.geothermField === true">
              <img src="/assets/icons/Success.svg" alt="success-icon" />
            </span>
          </ng-container>
          <!-- Display default content for other columns -->
          <span [matTooltip]="getRowDataByKey(row, columnName)"
            *ngIf="columnName !== 'soilType' && columnName !== 'status' && columnName !== 'rushed' && columnName !== 'geothermField'  && columnName !== 'isSplit'">{{
            getRowDataByKey(row, columnName) }}</span>
        </mat-cell>
      </ng-container>

      <!--action -->
      <ng-container matColumnDef="action" stickyEnd>
        <mat-header-cell class="header-text" *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button [matMenuTriggerFor]="menu"
          [disabled]="this.userService.roleCheck(['Manager', 'Reportwriter']) && row.isActive === false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="dashboard-menu mat-menu-box" #menu="matMenu" xPosition="before" yPosition="below">
            <button *ngIf="
                (userService.roleCheck(['Manager', 'Admin']) && row.isActive === true) ||
                (userService.roleCheck(['Manager', 'Reportwriter']) && this.myProjects)
              " mat-menu-item class="btn-hover" (click)="review(row.id, row.soilType)">
              <span class="menu-text">
                <img src="/assets/icons/File.svg" alt="File-icon" />&nbsp; View Submission Form
              </span>
            </button>
            <button *ngIf="
                (userService.roleCheck(['Manager', 'Admin','Reportwriter']) && shouldShowMergeButton(row))
                " mat-menu-item class="btn-hover" (click)="mergeProject(row.id, row.soilType)">
                <span class="menu-text">
                  <img src="/assets/icons/Merge.svg" alt="File-icon" />&nbsp; Merge Project
                </span>
            </button>
            <button mat-menu-item (click)="gotoWorksheet(row.id, row.worksheetGenerated, row.soilType)" *ngIf="
              row.worksheetGenerated === true &&row.status !== 'Samples Due To Arrive' &&
                ((userService.roleCheck(['Manager', 'Admin']) && row.isActive === true) ||
                  (userService.roleCheck(['Manager', 'Reportwriter', 'Labtech']) && this.myProjects))
              " [disabled]="this.userService.roleCheck(['Reportwriter', 'Labtech']) && !row.worksheetGenerated"
              [hidden]="
                (row.soilType === 'Pre Cast' && (row.worksheetGenerated === false || row.worksheetGenerated === null)) ||
                (row.soilType === 'Pre Cast' && row.worksheetGenerated === true && !allUserAction) ||
                (row.soilType === 'Native Soil' && row.worksheetGenerated === null)
              ">
              <span class="menu-text">
                <img src="/assets/icons/List.svg" alt="List" />&nbsp;
                {{
                row.soilType === 'Native Soil'
                ? row.worksheetGenerated === true
                ? 'View Worksheet'
                : ''
                : row.soilType === 'Pre Cast' && row.worksheetGenerated === true
                ? 'View Worksheet'
                : ''
                }}
              </span>
            </button>
            <button mat-menu-item (click)="openDialog(row, 'SetStatus')" *ngIf="true && row.isActive === true">
              <span class="menu-text">
                <img src="/assets/icons/Receipt.svg" alt="Done-icon" />&nbsp; Receive Shipment
              </span>
            </button>
            <button mat-menu-item (click)="openDialog(row, 'Notes')" *ngIf="
                (userService.roleCheck(['Manager', 'Admin']) && row.isActive === true) ||
                (userService.roleCheck(['Manager', 'Reportwriter']) && this.myProjects)
              ">
              <span class="menu-text">
                <img src="/assets/icons/Comment.svg" alt="Comment-icon" />&nbsp; View Notes
              </span>
            </button>
            <button mat-menu-item (click)="assignToReportWriter(row.id)" *ngIf="
                row.status === 'Lab Manager Review' &&
                this.userService.roleCheck(['Manager', 'Admin']) &&
                row.isActive === true
              ">
              <span class="menu-text">
                <img src="/assets/icons/User-multiple.svg" alt="File" />&nbsp; Assign To Report Writer
              </span>
            </button>
            <button mat-menu-item (click)="assignToMyself(row.id)" *ngIf="
                !this.myProjects &&
                ((row.status === 'Lab Manager Review' && this.userService.roleCheck(['Manager'])) ||
                  (row.status === 'Ready To Report' && this.userService.roleCheck(['Reportwriter']) && !row.geothermField))
              ">
              <span class="menu-text">
                <img src="/assets/icons/User-multiple.svg" alt="File" />&nbsp; Assign To Myself
              </span>
            </button>
            <button mat-menu-item *ngIf="false && row.isActive === true">
              <span class="menu-text">
                <img src="/assets/icons/Notification.svg" alt="Notification-icon" />&nbsp; Notify Customer
              </span>
            </button>
            <button mat-menu-item *ngIf="
                ((userService.roleCheck(['Admin']) && row.isActive === true) ||
                  (userService.roleCheck(['Reportwriter']) && this.myProjects)) &&
                (row.status === 'Ready To Report' ||
                  row.status === 'Initial Review' ||
                  row.status === 'Management Review' ||
                  row.status === 'Management Approved')
              " (click)="manageReport(row)">
              <span class="menu-text">
                <img src="/assets/icons/Bar-chart.svg" alt="chart-icon" />&nbsp; Manage Report
              </span>
            </button>
            <button mat-menu-item *ngIf="this.userService.roleCheck(['Admin'])"
              (click)="archiveRecord(row.id, row.soilType)">
              <span class="menu-text"> <img src="/assets/icons/Delete.svg" alt="Delete-icon" />&nbsp; Delete </span>
            </button>
            <button mat-menu-item *ngIf="this.userService.roleCheck(['Admin']) && row.isActive === true"
              (click)="makeRecordInactive(row.id)">
              <span class="menu-text">
                <img src="/assets/icons/Remove.svg" alt="Inactive-icon" width="20px" />&nbsp; Inactive
              </span>
            </button>
            <button mat-menu-item
              *ngIf="this.userService.roleCheck(['Admin', 'Manager', 'Reportwriter']) && row.isActive === false"
              (click)="restoreRecord(row.id)">
              <span class="menu-text">
                <img src="/assets/icons/Restore.svg" alt="Restore-icon" width="20px" />&nbsp; Restore
              </span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
    </mat-table>
  </section>
  <div class="dashboard-pagination" *ngIf="totalPages && projectRecords.length > 0">
    <mat-paginator [length]="totalRecords" #dashboarPaginator [pageIndex]="currentPage - 1"
      (page)="onPaginationNavigate($event)" [pageSize]="recordsPerPage" [pageSizeOptions]="[5, 10, 15]"
      showFirstLastButtons></mat-paginator>
  </div>

  <section class="no-search-section" *ngIf="
      (searchKeyWord && projectRecords.length === 0) ||
      (selectedProjectStatus && projectRecords.length === 0) ||
      (selectedMaterialTypes && projectRecords.length === 0)
    ">
    <div class="search-result">No such records found</div>
  </section>
</ng-template>