import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { MlData } from 'src/app/shared/models/ml-data.modal';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  projectInformation : IPrecastRecord;
  mlGraphData : MlData;
  constructor(private httpService: HttpService) { }
  public fetchReportData(projectId: string, soilType: string): Observable<any> {
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const url = soilType === 'precast'
      ? `reports/getprecastreport/${projectId}/${localTimeZoneOffset}`
      : `reports/getnativesoilreport/${projectId}`;
    return this.httpService.get(url);
  }

  public getSignature(): Observable<any> {
    const url = 'lookup/getsignaturetypes';
    return this.httpService.get(url);
  }

  public updateReport(flag:boolean, payload: any): Observable<any> {
    return this.httpService.post(`reports/updatereport/${flag}`, payload);
  }

  public updateGraphData(payload: any, soilType: string): Observable<any> {
    const url = soilType === 'precast'
      ? 'reports/updateprecastgraphdata'
      : 'reports/updatenativegraphdata';
    return this.httpService.post(url, payload);
  }

  public getGraphData(projectId: string, soilType: string): Observable<any> {
    const url = soilType === 'precast'
      ? `reports/getprecastgraphdata/${projectId}`
      : `reports/getnativegraphdata/${projectId}`;
    return this.httpService.get(url);
  }

  public getImage(url: string): Observable<any> {
    return this.httpService.get(url, {
      responseType: 'blob',
    });
  }

  public saveRecommend(payload: any, projectId: string): Observable<any> {
    return this.httpService.post(`reports/updaterecommendation/${projectId}`, payload);
  }

  public getRecommend(projectId: string): Observable<any> {
    return this.httpService.get(`reports/getrecommendation/${projectId}`);
  }
}
