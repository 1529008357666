import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MergeTable } from '../../models/merge-info.model';

@Component({
  selector: 'app-merge-table',
  templateUrl: './merge-table.component.html',
  styleUrls: ['./merge-table.component.scss'],
  providers: [DatePipe],
})
export class MergeTableComponent implements OnInit, OnChanges {
  @Input() dataSource: MergeTable[] = [];
  @Input() headerColumns: any[];
  @Input() displayedColumns: any[];
  @Input() soiltype: string;
  @Output() projectChecked = new EventEmitter<string[]>();

  parentDisplayColumns: string[] = [
    'projectNumber',
    'projectName',
    'soilType',
    'submitted',
    'sampleReceivedDate',
    'dueDate',
    'status',
  ];

  selectAllChecked = false;

  ngOnInit(): void {
    if (!this.dataSource) {
      this.dataSource = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.dataSource.forEach((project) => {
        if (project.isChecked === undefined) {
          project.isChecked = false; // Initialize if not already set
        }
      });
      console.log('Updated dataSource:', this.dataSource);
    }
  }

  shouldShowIndeterminateState(): boolean {
    return this.dataSource.length > 0 && !this.selectAllChecked && this.dataSource.some((p) => p.isChecked);
  }

  onCheckboxChange(): void {
    this.emitCheckedProjects();
    this.updateSelectAllState();
  }

  onSelectAllChange(): void {
    this.dataSource.forEach((project) => {
      project.isChecked = this.selectAllChecked;
    });
    this.emitCheckedProjects();
  }

  emitCheckedProjects(): void {
    const selectedIds = this.dataSource.filter((project) => project.isChecked).map((project) => project.id);
    this.projectChecked.emit(selectedIds);
    console.log('Selected project IDs:', selectedIds);
  }

  updateSelectAllState(): void {
    const totalProjects = this.dataSource.length;
    const checkedProjects = this.dataSource.filter((p) => p.isChecked).length;
    this.selectAllChecked = checkedProjects === totalProjects;
  }

  getDisplayDepth(item: any): string {
    if (item.stockPile && item.displayDepth === `${null}-${null}`) {
      return 'Stockpile';
    }
    if (item.displayDepth === `${null}-${null}`) {
      return '';
    }
    return item.displayDepth;
  }
}
