/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-dupe-class-members */
import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { UserService } from 'src/app/shared/services/user.service';
import { CustomDecimalValidator, ValidateOneDecimal } from 'src/app/shared/validators/one-decimal.validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMessage } from 'src/app/shared/models/message.modal';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { MoistureWorkSheet,
  NativeSoilType,
  ReceivedMCWorkSheet,
  WorkSheet,
  ProctorSheet } from '../../models/native-soil-worksheet.modal';
import { NativeSoilService } from '../../services/native-soil/native-soil.service';

@Component({
  selector: 'app-edit-native-soil-worksheet',
  templateUrl: './edit-native-soil-worksheet.component.html',
  styleUrls: ['./edit-native-soil-worksheet.component.scss'],
})
export class EditNativeSoilWorksheetComponent {
  public titleError = false;
  workSheetType = '';
  public worksheets: NativeSoilType[];
  workSheet: NativeSoilType;
  currentIndex = 0;
  headerText: string | undefined = '';
  actionDone = 'CLOSED';
  nextActive = true;
  previousActive = false;
  currentIndexHandling = '0';
  changeDetected = false;
  saveActive = false;
  isEdit = false;
  title = '';
  modalType = '';
  isBulk = false;
  isForm = false;
  WTypeActive = {
    received: false,
    moisture: false,
    worksheet: false,
    proctor: false,
  };

  dryPlusTareCheck = false;
  dryTrCheck = false;
  dryTrIntial = true;
  public selectedIndex = 0;
  enableOpenTechWorksheetButton = false;
  dryWetWeighterror = false;
  moistureInValid: boolean;
  asReceivedInComplete: boolean;
  moistureContentData: any;
  projectIdValue: any;
  public form: FormGroup = new FormGroup({
  });

  constructor(
    private mdr: MatDialogRef<EditNativeSoilWorksheetComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private userService: UserService,
    private nativeSoilService: NativeSoilService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.worksheets = data.worksheets;
    this.workSheetType = data.workSheetType;
    this.isForm = data.isForm;
    this.modalType = data.modalType;
    this.isBulk = data.isBulk;
    this.currentIndex = this.worksheets.findIndex((x: NativeSoilType) => x?.id === data.element.id);
    this.workSheet = this.worksheets[this.currentIndex];
    const wLength = this.worksheets.length;
    if (this.currentIndex > 0) {
      this.previousActive = true;
    }
    if (this.currentIndex < wLength) {
      this.nextActive = true;
    }
    if (this.worksheets.length === 1 || this.currentIndex === wLength - 1) {
      this.nextActive = false;
    }
    this.viewData();

    this.setWTypeActive(this.workSheetType);
    this.title = data.title;
  }

  ngOnInit() {
    this.isEdit = this.userService.roleCheck(['Manager', 'Labtech', 'Admin']);
    this.projectIdValue = this.workSheet?.projectId;
    if (this.workSheetType === 'workSheet') {
      this.getnativeSoilData();
    }
  }

  getnativeSoilData(): void {
    this.nativeSoilService.getNativeSoilWorkSheet(this.projectIdValue).subscribe({
      next: (response: any) => {
        const moistureWorksheets = response?.moistureWorksheets || [];
        this.moistureContentData = moistureWorksheets;
        this.buildForm();
      },
      error: (error) => {
        console.error('Error fetching native soil data:', error);
      },
    });
  }

  closeDialog(): void {
    this.mdr.close(this.actionDone);
  }

  buildForm() {
    if (this.isForm) {
      switch (this.workSheetType) {
        case 'receivedSheet':
          this.buildReceivedForm();
          break;
        case 'moistureWorksheets':
          this.buildMoistureSWTForm();
          break;
        case 'workSheet':
          this.buildWorkSheetForm();
          break;
        case 'proctorWorksheets':
          this.buildProctorForm();
          break;
        default:
      }
      this.patchForm();
    } else {
      this.saveActive = true;
      this.changeDetected = true;
    }
  }

  private setWTypeActive(type: string): void {
    switch (type) {
      case 'receivedSheet':
        this.WTypeActive.received = true;
        break;
      case 'moistureWorksheets':
        this.WTypeActive.moisture = true;
        break;
      case 'workSheet':
        this.WTypeActive.worksheet = true;
        break;
      case 'proctorWorksheets':
        this.WTypeActive.proctor = true;
        break;
      default:
    }
  }

  next(): void {
    this.dryTrCheck = false;
    this.dryPlusTareCheck = false;
    this.previousActive = true;
    this.saveActive = false;
    this.selectedIndex = 0;
    if (this.currentIndex < this.worksheets.length - 2) {
      this.nextActive = true;
    } else {
      this.nextActive = false;
    }
    const formvalue = this.form.value;
    const currentData = {
      ...this.workSheet,
    };
    this.currentIndexHandling = '+';
    this.updateData(formvalue, currentData);
    this.currentIndex += 1;
    if (this.currentIndex <= this.worksheets.length - 1) {
      this.viewData();
    }
    this.changeDetected = this.form.valid;
    this.inhouseKeyup();
  }

  prev(): void {
    this.dryTrCheck = false;
    this.dryPlusTareCheck = false;
    this.nextActive = true;
    this.saveActive = false;
    this.selectedIndex = 0;
    if (this.currentIndex > 1) {
      this.previousActive = true;
      this.nextActive = true;
    } else {
      this.previousActive = false;
    }
    const formvalue = this.form.value;
    const currentData = this.worksheets[this.currentIndex];
    this.currentIndexHandling = '-';
    this.updateData(formvalue, currentData);
    this.currentIndex -= 1;
    if (this.currentIndex >= 0) {
      this.viewData();
    }
    this.changeDetected = this.form.valid;
    this.inhouseKeyup();
  }

  done(): void {
    const formvalue = this.form.value;
    const currentData = {
      ...this.workSheet,
    };
    this.updateData(formvalue, currentData);
    if (this.currentIndex <= this.worksheets.length - 1) {
      this.viewData();
    }
    this.changeDetected = this.form.valid;
    this.mdr.close('UPDATED');
  }

  checkForDuplicate(arr: NativeSoilType[], value: string | undefined, id: string | undefined): boolean {
    let duplicate = false;
    arr.forEach((elem: NativeSoilType) => {
      // if (elem.inHouseId) {
      //   if (elem.inHouseId === value && elem.id !== id) {
      //     duplicate = true;
      //   }
      // }
    });
    return duplicate;
  }

  updateHeaderText(type: string): void {
    let moistureContent = 0;
    switch (type) {
      case 'receivedSheet':
        this.headerText = `Sample ${this.workSheet?.sampleId}`;
        break;
      case 'moistureWorksheets':
        if (this.modalType === 'mswt') {
          this.headerText = `Sample ${this.workSheet?.sampleId} @ ${this.workSheet?.compactionEffort}% MDD`;
        } else {
          const mc = this.getFormattedMC(this.workSheet?.moistureContent);
          this.headerText = `Sample ${this.workSheet?.sampleId} @ ${this.workSheet?.compactionEffort}% MDD & ${mc} M/C`;
        }
        break;
      case 'workSheet':
        if (this.workSheet.worksheetType === 'Bulk') {
          let mc = '';
          if (this.workSheet?.clientSpecifiedMoistureContent) {
            const m = this.workSheet?.clientSpecifiedMoistureContent;
            moistureContent = +m;
            if (moistureContent === 0) {
              mc = this.getFormattedMC(this.workSheet.moistureContent);
            } else if (moistureContent < 0) {
              mc = `(${m})`;
            } else {
              mc = m;
            }
          } else {
            mc = this.getFormattedMC(this.workSheet.moistureContent);
          }
          this.headerText = `Sample ${this.workSheet.sampleId} @ ${this.workSheet.effort}% MDD & ${mc} M/C`;
        } else {
          this.headerText = `Sample ${this.workSheet.sampleId}`;
        }
        break;
      case 'proctorWorksheets':
        if (this.modalType === 'mswt') {
          this.headerText = `Sample ${this.workSheet.sampleId}`;
        } else {
          this.headerText = `Sample ${this.workSheet.sampleId} @ ${this.workSheet.moistureContent} M/C`;
        }
        break;
      default:
    }
  }

  getFormattedMC(value: string): string {
    let mc = '';
    const moistureContent = +value;
    if (moistureContent < 0) {
      mc = `(${value})`;
    } else {
      mc = value;
    }
    return mc;
  }

  save(): void {
    const formvalue = this.form.value;
    const currentData = {
      ...this.workSheet,
    };
    this.currentIndexHandling = '0';
    if (this.form.valid) {
      this.updateData(formvalue, currentData);
      this.saveActive = false;
    }
  }

  private updateData(formvalue: any, currentData: any) {
    const payload = this.setPayload(formvalue, currentData);
    if (this.changeDetected) {
      if (!(this.checkForDuplicate(this.worksheets, payload.inHouseId, payload.id) && !this.workSheet.reUse)) {
        this.titleError = false;
        this.actionDone = 'UPDATED';
        if (payload?.depth === '') {
          payload.depth = null;
        }
        if (payload?.endDepth === '') {
          payload.endDepth = null;
        }

        this.callAPI(payload);
        this.changeDetected = false;
      } else {
        this.titleError = true;
      }
    }
  }

  private callAPI(payload: any): void {
    if (payload.assignedTo) {
      delete payload.assignedTo;
    }
    switch (this.workSheetType) {
      case 'receivedSheet':
        if (this.receivedSheetCheckForComplete(payload)) {
          payload.status = true;
        } else {
          payload.status = false;
        }
        this.callReceivedWorkSheetAPI(payload);
        break;
      case 'moistureWorksheets':
        if (!this.isForm && this.checkForMoisturesheetsValid(payload)) {
          payload.status = true;
        }
        if (this.modalType === 'mswt') {
          payload.multiPointMoisture = payload.multiPointMoisture.map((obj: any) => obj.moisture);
        }
        this.callMoistureWorkSheetAPI(payload);
        break;
      case 'workSheet':
        if (this.worksheetCheckForComplete(payload)) {
          payload.status = true;
        } else {
          payload.status = false;
        }
        this.callWorkSheetAPI(payload);
        break;
      case 'proctorWorksheets':
        if (this.proctorCheckForComplete(payload)) {
          payload.status = true;
        } else {
          payload.status = false;
        }
        this.callProctorSheetAPI(payload);
        break;
      default:
    }
  }

  callReceivedWorkSheetAPI(payload: ReceivedMCWorkSheet): void {
    this.nativeSoilService.updateReceivedWorkSheet(payload).subscribe({
      next: (response: any) => {
        response.sampleId = payload.sampleId;
        this.setResponseData(response);
      },
      error: () => {},
    });
  }

  callMoistureWorkSheetAPI(payload: MoistureWorkSheet): void {
    this.nativeSoilService.updateMoistureWorkSheet(payload, this.modalType).subscribe({
      next: (response) => {
        response.sampleId = payload.sampleId;
        this.setResponseData(response);
      },
      error: () => {},
    });
  }

  callWorkSheetAPI(payload: WorkSheet): void {
    this.nativeSoilService.updateWorkSheet(payload).subscribe({
      next: (response) => {
        response.sampleId = payload.sampleId;
        this.nativeSoilService.getNativeSoilWorkSheet(this.projectIdValue).subscribe({
          next: (worksheet: any) => {
            const nativeWorksheet = worksheet.nativeSoilWorksheets.filter(
              (element: any) => !element.groupOrder || element.worksheetType === 'Bulk',
            );
            this.worksheets = nativeWorksheet;
            this.setResponseData(response);
          },
        });
      },
      error: () => {},
    });
  }

  callProctorSheetAPI(payload: ProctorSheet): void {
    this.nativeSoilService.updateProctorWorkSheet(payload).subscribe({
      next: (response) => {
        response.sampleId = payload.sampleId;
        this.setResponseData(response);
      },
      error: () => {},
    });
  }

  setResponseData(response: any): void {
    switch (this.currentIndexHandling) {
      case '+':
        this.worksheets[this.currentIndex - 1] = response;
        break;
      case '-':
        this.worksheets[this.currentIndex + 1] = response;
        break;
      default:
        this.worksheets[this.currentIndex] = response;
    }
    this.workSheet = this.worksheets[this.currentIndex];
    this.buildForm();
    this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['default-snackbar'],
    });
  }

  private setPayload(form: any, payload: any): any {
    Object.keys(form).forEach((key: any) => {
      payload[key] = form[key];
    });
    return payload;
  }

  private viewData(): void {
    this.workSheet = this.worksheets[this.currentIndex];
    this.updateHeaderText(this.workSheetType);
    this.buildForm();
  }

  buildReceivedForm(): void {
    this.form = new FormGroup({
      inHouseId: new FormControl(this.workSheet?.inHouseId, [Validators.required]),
      wetWeight: new FormControl(this.workSheet?.wetWeight, [Validators.required]),
      dryWeight: new FormControl(this.workSheet?.dryWeight, [Validators.required]),
    });
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.changeDetected = true;
        this.saveActive = true;
      } else {
        this.changeDetected = false;
        this.saveActive = false;
      }
    });
  }

  buildMoistureSWTForm(): void {
    this.form = new FormGroup({
      drySoil: new FormControl(this.workSheet.drySoil, [Validators.required]),
      moistureContent: new FormControl(
        {
          value: this.workSheet.moistureContent,
          disabled: true,
        },
        [Validators.required, ValidateOneDecimal()],
      ),
      multiPointMoisture: new FormArray([]),
      reUse: new FormControl(this.workSheet.reUse),
    });
    this.workSheet.multiPointMoisture.forEach((value) => {
      this.addMultiPointMoisture(value);
    });
    this.asReceivedInComplete = Number(this.workSheet.moistureContent) === 0;
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.changeDetected = true;
        this.saveActive = true;
      } else {
        this.changeDetected = false;
        this.saveActive = false;
      }
    });
  }

  get getMultiPointMoisture(): FormArray {
    return this.form?.controls['multiPointMoisture'] as FormArray;
  }

  private createMultiPointMoisture(moisture: string): FormGroup {
    return this.fb.group({
      moisture: [moisture, [Validators.required, ValidateOneDecimal()]],
    });
  }

  addMultiPointMoisture(moisture = ''): void {
    if (this.getMultiPointMoisture.length < 3) {
      this.getMultiPointMoisture.push(this.createMultiPointMoisture(moisture));
    }
  }

  removeMultiPointMoisture() {
    if (this.getMultiPointMoisture.length > 0) {
      this.getMultiPointMoisture.removeAt(this.getMultiPointMoisture.length - 1);
    }
    this.validateMoisture();
  }

  buildWorkSheetForm(): void {
    this.form = new FormGroup({
      inHouseId: new FormControl(
        {
          value: this.workSheet.inHouseId,
          disabled: this.workSheet.reUse && !this.workSheet.parentRow,
        },
        [Validators.required],
      ),
      mouldPlusProbeTare: new FormControl(this.workSheet.mouldPlusProbeTare, [Validators.required]),
      soilDiameter: new FormControl(this.workSheet.soilDiameter, [Validators.required, CustomDecimalValidator()]),
      tubeHeight: new FormControl(this.workSheet.tubeHeight, [Validators.required, CustomDecimalValidator()]),
      minus: new FormControl(this.workSheet.minus, [Validators.required, CustomDecimalValidator()]),
      wetPlusTare: new FormControl(this.workSheet.wetPlusTare, [Validators.required]),
      wetTr: new FormControl(this.workSheet.wetTr, [Validators.required]),
      dryPlusTare: new FormControl(this.workSheet.dryPlusTare, [Validators.required]),
      dryTr: new FormControl(this.workSheet.dryTr, [Validators.required]),
      multiPointTests: new FormArray([]),
      weight: new FormControl({
        value: this.weightPerLayerCalc(),
      }),
    });

    this.form.get('soilDiameter')?.valueChanges.subscribe(() => {
      this.calculateWeightPerLayer();
    });

    this.form.get('tubeHeight')?.valueChanges.subscribe(() => {
      this.calculateWeightPerLayer();
    });

    this.form.get('minus')?.valueChanges.subscribe(() => {
      this.calculateWeightPerLayer();
    });

    this.workSheet.multiPointTests.forEach((value, index) => {
      this.getmultiPointTests.push(this.createMultiPoint());
      this.getmultiPointTests.at(index).patchValue(value);
    });
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.changeDetected = true;
        this.saveActive = true;
      } else {
        this.changeDetected = false;
        this.saveActive = false;
      }
    });
  }

  calculateWeightPerLayer() {
    this.form.patchValue({
      weight: this.weightPerLayerCalc(),
    });
  }

  weightPerLayerCalc(): any {
    const soilDiameter = parseFloat(this.form.get('soilDiameter')?.value) || 0;
    const tubeHeight = parseFloat(this.form.get('tubeHeight')?.value) || 0;
    const minus = parseFloat(this.form.get('minus')?.value) || 0;
    const pi = 3.14159;
    const mouldVolume = ((pi * soilDiameter * soilDiameter) / 4000) * (tubeHeight - minus);
    if (Array.isArray(this.moistureContentData)) {
      for (const worksheet of this.moistureContentData) {
        if (this.workSheet.nativeSoilSampleId === worksheet.nativeSoilSampleId) {
          const moisture = this.workSheet.parentRow
            ? Number(worksheet.moistureContent)
            : Number(this.workSheet.labSpecMoistureContent);
          const bulkDensity = worksheet.proctorDryDensity * (1 + moisture / 100);
          const weightperlayer = ((mouldVolume * bulkDensity * worksheet.compactionEffort) / 100 / 62.4 / 5).toFixed(1);
          return weightperlayer;
        }
      }
    }
    return 0;
  }

  createMultiPoint(): FormGroup {
    return this.fb.group({
      wetPlusTare: ['', [Validators.required]],
      wetTr: ['', [Validators.required]],
    });
  }

  get getmultiPointTests(): FormArray {
    return this.form.controls['multiPointTests'] as FormArray;
  }

  addMultiPoint(): void {
    if (this.getmultiPointTests.length < 3) {
      this.getmultiPointTests.push(this.createMultiPoint());
    }
  }

  removeMultiPoint(index: number) {
    this.getmultiPointTests.removeAt(index);
    this.form.markAsDirty();
  }

  buildProctorForm(): void {
    if (this.modalType === 'mswt') {
      this.form = new FormGroup({
        moistureContent: new FormControl(this.workSheet.moistureContent, [Validators.required, ValidateOneDecimal()]),
        drySoil: new FormControl(this.workSheet.drySoil, [Validators.required]),
      });
    } else {
      this.form = new FormGroup({
        mouldAndSoilWeight: new FormControl(this.workSheet.mouldAndSoilWeight, [Validators.required]),
        proctorMouldWeight: new FormControl(this.workSheet.proctorMouldWeight, [Validators.required]),
      });
    }
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.changeDetected = true;
        this.saveActive = true;
      } else {
        this.changeDetected = false;
        this.saveActive = false;
      }
    });
  }

  patchForm(): void {
    this.form.patchValue(this.workSheet);
  }

  checkForDryWeightValidation(): void {
    let count = 0;
    const dryPlusTare = +this.form.controls['dryPlusTare'].value;
    const wetPlusTare = +this.form.controls['wetPlusTare'].value;
    this.getmultiPointTests.value.forEach((test: any) => {
      if (Number(dryPlusTare) !== 0 && Number(test.wetPlusTare) !== 0) {
        if (Number(dryPlusTare) > Number(test.wetPlusTare) || Number(dryPlusTare) === Number(test.wetPlusTare)) {
          count += 1;
        }
      }
    });
    if (
      count > 0
      || ((Number(dryPlusTare) > Number(wetPlusTare) || Number(dryPlusTare) === Number(wetPlusTare))
        && Number(dryPlusTare) !== 0
        && Number(wetPlusTare) !== 0)
    ) {
      this.changeDetected = false;
      this.dryPlusTareCheck = true;
      this.form.controls['dryPlusTare'].setErrors({
        Invalid: true,
      });
    } else {
      this.dryPlusTareCheck = false;
      this.form.controls['dryPlusTare'].setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  checkForDryTRValidation(): void {
    let count = 0;
    const dryTr = +this.form.controls['dryTr'].value;
    const wetTr = +this.form.controls['wetTr'].value;
    this.getmultiPointTests.value.forEach((test: any) => {
      if (Number(dryTr) !== 0 && Number(test.wetTr) !== 0) {
        if (Number(dryTr) < Number(test.wetTr) || Number(dryTr) === Number(test.wetTr)) {
          count += 1;
        }
      }
    });
    if (
      count > 0
      || ((Number(dryTr) < Number(wetTr) || Number(dryTr) === Number(wetTr)) && Number(dryTr) !== 0 && Number(wetTr) !== 0)
    ) {
      this.changeDetected = false;
      this.dryTrCheck = true;
      this.form.controls['dryTr'].setErrors({
        Invalid: true,
      });
    } else {
      this.dryTrCheck = false;
      this.form.controls['dryTr'].setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  checkForWetWightReceived(): void {
    const dryWeight = this.form.controls['dryWeight'].value;
    const wetWeight = this.form.controls['wetWeight'].value;
    if (dryWeight !== 0 && wetWeight !== 0) {
      if (Number(dryWeight) > Number(wetWeight)) {
        this.dryWetWeighterror = true;
        this.form.controls['dryWeight'].setErrors({
          Invalid: true,
        });
      } else {
        this.dryWetWeighterror = false;
        this.form.controls['dryWeight'].setErrors(null);
      }
      this.form.updateValueAndValidity();
    }
  }

  inhouseKeyup(): void {
    if (
      this.checkForDuplicate(this.worksheets, this.form.value.inHouseId, this.workSheet.id)
      && !this.workSheet.reUse
    ) {
      this.titleError = true;
    } else {
      this.titleError = false;
    }
  }

  receivedSheetCheckForComplete(payload: ReceivedMCWorkSheet): boolean {
    if (payload.inHouseId !== '' && Number(payload.wetWeight) !== 0 && Number(payload.dryWeight) !== 0) {
      return true;
    }
    return false;
  }

  checkForMoisturesheetsValid(worksheet: NativeSoilType): boolean {
    if (Number(worksheet.drySoil) !== 0 && Number(worksheet.moistureContent) !== 0) {
      return true;
    }
    return false;
  }

  worksheetCheckForComplete(payload: WorkSheet): boolean {
    if (
      payload.inHouseId !== ''
      && Number(payload.mouldPlusProbeTare) !== 0
      && Number(payload.soilDiameter) !== 0
      && Number(payload.tubeHeight) !== 0
      && Number(payload.wetPlusTare) !== 0
      && Number(payload.wetTr) !== 0
      && Number(payload.dryPlusTare) !== 0
      && Number(payload.dryTr) !== 0
    ) {
      return true;
    }
    return false;
  }

  proctorCheckForComplete(payload: ProctorSheet): boolean {
    if (
      Number(payload.moistureContent) !== 0
      && Number(payload.drySoil) !== 0
      && Number(payload.mouldAndSoilWeight) !== 0
      && Number(payload.proctorMouldWeight) !== 0
    ) {
      return true;
    }
    return false;
  }

  validateMoisture() {
    let count = 0;
    this.getMultiPointMoisture.controls.forEach((cntrl) => {
      if (
        Number(cntrl.value.moisture) > Number(this.form.get('moistureContent')?.value)
        || Number(cntrl.value.moisture) === Number(this.form.get('moistureContent')?.value)
      ) {
        count += 1;
      }
    });
    this.moistureInValid = count > 0;
  }

  checkForAllValidations() {
    this.checkForDryWeightValidation();
    if (this.workSheet?.worksheetType !== 'Tube') {
      this.checkForWetPlusTareValidation();
    }
  }

  checkForWetPlusTareValidation(): void {
    const weight = +this.form.controls['weight']?.value || 0;
    const mouldPlusProbeTare = +this.form.controls['mouldPlusProbeTare']?.value || 0;
    const wetPlusTare = +this.form.controls['wetPlusTare']?.value || 0;
    // Round the sum to the nearest integer
    const roundedSum = Math.round(5 * weight + mouldPlusProbeTare);
    if (wetPlusTare !== roundedSum) {
      this.showPopUpMessage(messagesConfig.errorSubmissionMessage);
    }
    this.form.updateValueAndValidity();
  }

  showPopUpMessage(message: IMessage): void {
    const dialogRef = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: message,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });

    dialogRef.afterClosed().subscribe();
  }
}
