<div class="edit-report-wrapper mat-elevation-z1" *ngIf="previewData && !exportShow">
  <form [formGroup]="form">
    <h1>Thermal Analysis Report</h1>
    <p>Date Modified: {{ previewData.updatedAt | utcToLocal }}</p>
    <div class="form-wrapper">
      <div class="col-2 b1">
        <mat-label class="mat-label">Submittal Date</mat-label>
        <mat-form-field appearance="outline">
          <input class="mat-date-input" readonly matInput formControlName="reportDate" [matDatepicker]="picker"  [min]="today" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-2 online-type b2">
        <mat-label class="mat-label">Address To</mat-label>
        <mat-form-field appearance="outline" class="mat-form-field-width">
          <mat-select disableOptionCentering formControlName="isBilling" placeholder="Select">
            <mat-option [value]="false">Company</mat-option>
            <mat-option [value]="true">Billing</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-11 b3">
        <mat-label class="mat-label">Main Body</mat-label>
        <div class="c-w">
          <mat-form-field appearance="outline" *ngIf="soilType === 'nativesoil'">
            <input matInput formControlName="mainBodyIntro" />
          </mat-form-field>
          <!-- <app-richtext-editor
            (contentChanged)="onMainBodyChanged($event)"
            formControlName="mainBody"></app-richtext-editor> -->
          <textarea matInput formControlName="mainBody" rows="5"></textarea>
        </div>
      </div>

      <div class="col-11 b3" *ngIf="soilType === 'nativesoil'">
        <mat-label class="mat-label">Native Soil Sample Table</mat-label>
        <div class="redittable-sample-wrapper">
          <table>
            <tr>
              <th rowspan="2">
                <span>Sample ID</span>
              </th>
              <th rowspan="2">
                <span>Depth </span>
                <span>(ft) </span>
              </th>
              <th rowspan="2" *ngIf="previewData.reportType !== 'Tube'">
                <span>Effort </span>
                <span>(%) </span>
              </th>
              <th rowspan="2">
                <span>Description</span> <span>({{ previewData.companyName }})</span>
              </th>
              <th colspan="2" class="thermal-res">
                <span>Thermal Resistivity</span>
                <span>(°C-cm/W)</span>
              </th>
              <th rowspan="2" class="mc">
                <span>Moisture Content</span>
                <span>(%) </span>
              </th>
              <th rowspan="2" class="dry-density">
                <span>Dry Density</span>
                <span>(lb/ft3) </span>
              </th>
            </tr>
            <tr class="b">
              <th class="c">
                <span>Wet</span>
              </th>
              <th class="c">
                <span>Dry</span>
              </th>
            </tr>

            <!-- Use the helper function to get FormControl -->
            <tr *ngFor="let thermalData of thermalDataArray.controls; let i = index">
              <td>
                <input [formControl]="getThermalDataControl('sampleId', i)" maxlength="50" />
              </td>
              <td>
                <input [formControl]="getThermalDataControl('depth', i)" (input)="onInputChanged(i)" />
                <div *ngIf="getThermalDataControl('depth', i).hasError('invalidDepth')">
                  <small class="error">Depth must be either a number (e.g., 1.22) or 'Stockpile'.</small>
                </div>
              </td>
              <td *ngIf="previewData.reportType !== 'Tube'">
                {{ thermalData.get('effort')?.value }}
              </td>
              <td>
                <input [formControl]="getThermalDataControl('description', i)" />
              </td>
              <td>{{ thermalData.get('wet')?.value }}</td>
              <td>{{ thermalData.get('dry')?.value }}</td>
              <td>{{ thermalData.get('moistureContent')?.value }}</td>
              <td>{{ thermalData.get('density')?.value }}</td>
            </tr>
          </table>
        </div>
      </div>
      <br />

      <div class="col-11 b3 b4">
        <mat-label class="mat-label">Comments</mat-label>
        <div class="c-w">
          <span>Customer Comments</span>
          <div class="comment-disabled">
            <div>
              <span *ngIf="soilType === 'precast' && form.value?.commentPart1">Concrete</span>
              <mat-form-field appearance="outline" *ngIf="form.value?.commentPart1">
                <input matInput formControlName="commentPart1" />
              </mat-form-field>
            </div>
            <div>
              <span *ngIf="soilType === 'precast' && form.value?.commentPart2">FTB</span>
              <mat-form-field appearance="outline" *ngIf="form.value?.commentPart2">
                <input matInput formControlName="commentPart2" />
              </mat-form-field>
            </div>
            <div>
              <span *ngIf="soilType === 'precast' && form.value?.commentPart3">Grout/Slurry</span>
              <mat-form-field appearance="outline">
                <input matInput formControlName="commentPart3" />
              </mat-form-field>
            </div>
            <div *ngIf="soilType === 'precast'">
              
              <div *ngIf="form.value?.commentPart4">
                <span>Other</span>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commentPart4" />
                </mat-form-field>
              </div>
              <div >
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commentPart5" />
                </mat-form-field>
              </div> 
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="col-11 b4">
        <mat-label class="mat-label">Geotherm Comments</mat-label>
        <div class="c-w">
          <app-richtext-editor
          [minHeight]="'100px'"
          [content]="geothermComment"
          (contentChanged)="onContentChanged($event)"
        ></app-richtext-editor>
        </div>
      </div> -->
      <br />
      <div class="col-2 b4">
        <mat-label class="mat-label">Signature</mat-label>
        <mat-form-field appearance="outline">
          <mat-select disableOptionCentering formControlName="signatureTypeId" placeholder="Select">
            <mat-option *ngFor="let sig of sigArray" [value]="sig.id" class="arrow">
              {{ sig.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-label class="mat-label">Units of Measurement</mat-label>
        <div class="radio-button-container">
          <div class="radio-button-group">
          <span class="radio-button-label">Imperial</span> <input type="radio" formControlName="unitType" value="Imperial" class="radio-button"/>
          </div>
          <div class="radio-button-group">
          <span class="radio-button-label">Metric</span> <input type="radio" formControlName="unitType"  value="Metric" class="radio-button"/>
          </div>
        </div> -->
      </div>
      <div class="button-wrapper">
        <button mat-raised-button [disabled]="previewDisabled" (click)="preview()">PREVIEW</button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">SAVE</button>
      </div>
    </div>
  </form>
</div>

<div class="default-error" *ngIf="exportShow">
  <span>Please generate the report locally.</span>
</div>
<div class="edit-report-wrapper mat-elevation-z1">
  <h1>Figures</h1>
  <p *ngIf="previewData">Date Modified: {{ previewData.updatedAt | utcToLocal }}</p>
  <div class="progress-wrapper">
    <div class="tx-w">
      <span class="l">Progress</span>
      <span class="r">{{ figureCompletedCount }} of {{ figureCount }}</span>
      <div class="clearfix"></div>
    </div>
    <div>
      <div
        class="gr"
        *ngIf="figureCompletedCount !== 0"
        [style.width.%]="(figureCompletedCount / figureCount) * 100"></div>
      <div class="sh"></div>
    </div>
    <div class="e-button-wrapper">
      <button mat-raised-button color="primary" [routerLink]="['/report/thermal', soilType, projectId]">EDIT</button>
    </div>
  </div>
</div>
