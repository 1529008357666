import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { REPORTPREVIEW, THERMALDATA } from 'src/app/shared/models/i-report-preview.modal';
import { DepthValidator } from 'src/app/shared/validators/one-decimal.validator';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss'],
})
export class EditReportComponent implements OnInit {
  mainBody = '';
  today: Date = new Date();
  @Input() previewData: REPORTPREVIEW;
  @Input() sigArray: ISIGNATURE[];
  @Input() soilType: string;
  @Input() projectId: string;
  @Input() hasBillingInfo: boolean;
  @Input() figureCompletedCount: number;
  @Input() figureCount: number;
  @Input() exportShow: boolean;
  form: FormGroup;
  previewDisabled = false;
  savedDone = false;
  editTable = false;
  geothermComment = '';
  @Output() previewClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() savedNotify: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  initialThermalData: any[] = [];
  constructor(
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb?.group({
      id: [''],
      revisionDate: [''],
      header: [''],
      subHeader: [''],
      reportDate: ['', Validators.required],
      isBilling: [false, Validators.required],
      mainBody: [''],
      geothermComment: [''],
      commentPart1: [''],
      commentPart2: [''],
      commentPart3: [''],
      signatureTypeId: [''],
      unitType: [''],
      thermalData: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    if (this.soilType === SoilType.Precast) {
      this.addNativeControls();
    }
    if (this.soilType === SoilType.Native) {
      this.form.addControl('mainBodyIntro', this.fb.control('', Validators.required));
    }
    if (this.previewData?.mainBody.includes('xxxxx')) {
      const figureText = this.figureCount === 1 ? 'Figure 1' : `Figures 1-${this.figureCount}`;
      this.previewData.mainBody = this.previewData.mainBody.replace('xxxxx', figureText);
    }
    if (this.previewData?.thermalData?.length) {
      this.previewData.thermalData.forEach((thermalDataItem: THERMALDATA) => {
        this.addThermalDataControl(thermalDataItem);
      });
    }
    // const thermalData = this.getThermalData();
    // console.log(thermalData);
    if (this.previewData) {
      this.form.patchValue(this.previewData);
    }
    this.geothermComment = this.form.get('geothermComment')?.value || '';

    // Update the rich text editor when the form control value changes
    this.form.get('geothermComment')?.valueChanges.subscribe((value) => {
      if (this.geothermComment !== value) {
        this.geothermComment = value;
      }
    });
    this.initializeForm();
    this.form?.valueChanges?.subscribe(() => {
      this.editTable = true;
      this.previewDisabled = true;
    });
  }

  addNativeControls(): void {
  //  this.form.addControl('commentPart3', this.fb.control(''));
    this.form.addControl('commentPart4', this.fb.control(''));
    this.form.addControl('commentPart5', this.fb.control(''));
  }

  save(): void {
    this.savedDone = false;
    const geothermCommentValue = this.form.get('geothermComment')?.value;
    if (geothermCommentValue === '<p><br></p>' || geothermCommentValue?.trim() === '<p><br></p>') {
      this.form.get('geothermComment')?.setValue('');
    }
    this.editTable = this.compareValues();
    this.reportService.updateReport(this.editTable, this.form.value)?.subscribe({
      next: () => {
        this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.reportService?.fetchReportData(this.projectId, this.soilType).subscribe({
          next: (report: REPORTPREVIEW) => {
            this.previewData.updatedAt = report.updatedAt;
            this.previewDisabled = false;
            this.savedDone = true;
            this.savedNoti();
          },
        });
      },
    });
  }

  preview() {
    this.previewClicked.emit(this.savedDone);
    this.savedDone = false;
  }

  savedNoti() {
    this.savedNotify.emit(this.savedDone);
    this.savedDone = false;
  }

  onContentChanged(content: string) {
    this.form.get('geothermComment')?.setValue(content, {
      emitEvent: false,
    });
  }

  onMainBodyChanged(content: string) {
    this.form.patchValue({
      mainBody: content,
    });
  }

  initializeForm() {
    if (this.previewData) {
      console.log('Initializing form with Preview Data:', this.previewData);

      // Clear any existing controls
      this.thermalDataArray?.clear();
      // Patch values into form
      // this.form.patchValue(this.previewData);
      // Add thermal data form controls
      if (Array.isArray(this.previewData.thermalData)) {
        this.previewData.thermalData.forEach((thermalDataItem: THERMALDATA) => {
          this.addThermalDataControl(thermalDataItem);
        });
      }

      // Store initial thermal data for comparison
      this.initialThermalData = [...(this.previewData?.thermalData || [])];
    }
  }

  addThermalDataControl(thermalDataItem: THERMALDATA) {
    const thermalDataFormGroup = this.fb.group({
      id: new FormControl(thermalDataItem.id),
      sampleId: new FormControl(thermalDataItem.sampleId, [Validators.maxLength(50)]),
      depth: new FormControl(thermalDataItem.depth, [DepthValidator()]),
      description: new FormControl(thermalDataItem.description),
      wet: new FormControl(thermalDataItem.wet),
      dry: new FormControl(thermalDataItem.dry),
      moistureContent: new FormControl(thermalDataItem.moistureContent),
      density: new FormControl(thermalDataItem.density),
      effort: new FormControl(thermalDataItem.effort),
    });

    this.thermalDataArray.push(thermalDataFormGroup);
    this.cdr.detectChanges();
  }

  compareValues(): boolean {
    if (!this.thermalDataArray || this.thermalDataArray.length === 0) {
      return false;
    }
    const thermalDataControls = this.thermalDataArray.controls;
    for (let i = 0; i < thermalDataControls.length; i++) {
      const currentValues = {
        sampleId: thermalDataControls[i].get('sampleId')?.value,
        depth: thermalDataControls[i].get('depth')?.value,
        description: thermalDataControls[i].get('description')?.value,
      };
      const initialValues = this.initialThermalData[i] || {
      };

      if (
        currentValues.sampleId !== initialValues.sampleId
        || currentValues.depth !== initialValues.depth
        || currentValues.description !== initialValues.description
      ) {
        return true;
      }
    }
    return false;
  }

  onInputChanged(index: number) {
    const depthControl = this.getThermalDataControl('depth', index);
    // Update the form control and trigger validation
    depthControl.updateValueAndValidity();
  }

  getThermalData() {
    return this.form.get('thermalData')?.value;
  }

  getThermalDataControl(controlName: string, index: number): FormControl {
    const thermalDataGroup = this.thermalDataArray.at(index) as FormGroup;
    return thermalDataGroup.get(controlName) as FormControl;
  }

  get thermalDataArray() {
    return this.form.get('thermalData') as FormArray;
  }
}
