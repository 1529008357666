/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable no-trailing-spaces */
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewPayload } from 'src/app/shared/models/worksheet-checkbox.model';
import { FormService } from 'src/app/shared/services/form.service';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { CleanupService } from 'src/app/shared/services/cleanup.service';
import { takeUntil } from 'rxjs/operators';
import { IDropdown } from 'src/app/shared/models/i-dropdown-type.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { IMessage } from 'src/app/shared/models/message.modal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { SharedService } from 'src/app/features/service/shared.service';
import { PrecastSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { precastMergeColumns } from '../../constants/pre-merge-table-columns';
import { nativeMergeColumns } from '../../constants/ns-merge-table-columns';
import { MergeTable } from '../../models/merge-info.model';

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.scss'],
})
export class MergeComponent implements OnInit {
  soilType = '';
  projectId: string;
  displayedColumns: string[] = [];
  headerColumns: object[] = [];
  dataSource: MergeTable[];
  userAccount: any;
  userRoles: string[] = [];
  companyInformation: ICompany;
  projectNumber: any;
  formData: IPrecastRecord;
  nativeSoilSampleTypes: IDropdown[];
  viewSubmissionInfo: ReviewPayload;
  selectedProjectIds: string[] = [];
  updateSuccess: MatDialogRef<MessageModalComponent>;
  projects = [];
  selectedProjectId: string;
  copyProjectId: string | null;
  precastSampleTypes : PrecastSampleType [] = [];
  showBtn = false;
  pageLeaveDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private formService: FormService,
    private ApiService: PrecastService,
    public dialog: MatDialog,
    private cleanupService: CleanupService,
    private sharedService: SharedService,
    private lookupService:LookupService,
  ) {}

  ngOnInit(): void {
    const sampleType = this.activatedRoute.snapshot.paramMap.get('sampletype');
    this.projectId = this.activatedRoute.snapshot.paramMap.get('id') || '';
    this.setSoilType(sampleType);
    if (this.soilType === 'nativesoil' || this.soilType === 'precast') {
      this.setSoilConfigData();
    }
    if (this.projectId) {
      this.getCompanydetails(this.projectId, this.soilType);
      this.getPrecastSampleTypes();
    }
  }

  setSoilType(sampleType: string | null): void {
    if (sampleType === 'nativesoil') {
      this.soilType = 'nativesoil';
    } else if (sampleType === 'precast') {
      this.soilType = 'precast';
    } else {
      this.router.navigate(['/']);
    }
  }

  getCompanydetails(projectId: string, soilType: string) {
    this.formService.fetchFormData(projectId, soilType).subscribe({
      next: (data) => {
        if (data) {
          this.setFormData(data);

          this.formService.updateData(this.formData);
        }
      },
      error: () => {},
    });
  }

  setSoilConfigData(): void {
    if (this.soilType === 'nativesoil') {
      this.headerColumns = nativeMergeColumns;
      this.headerColumns.forEach((elem: any) => {
        this.displayedColumns.push(elem.name);
      });
    } else if (this.soilType === 'precast') {
      this.headerColumns = precastMergeColumns;
      this.headerColumns.forEach((elem: any) => {
        this.displayedColumns.push(elem.name);
      });
    }
  }

  getPrecastSampleTypes() {
    this.lookupService.getPrecastSampletypes().subscribe({
      next: (res: PrecastSampleType[]) => {
        this.precastSampleTypes = res;
      },
    });
  }

  setFormData(data: IPrecastRecord): void {
    this.formData = data;
    this.companyInformation = {
      ...this.formData.company,
      ...this.formData.project,
    };
    this.projectNumber = this.formData.project.projectNumber;
    if (this.projectNumber) {
      this.mergeTableDetails(this.projectNumber);
      console.log('data', this.dataSource);
    }
  }

  /** get call API */
  mergeTableDetails(projectNumber: any): void {
    this.ApiService.getMergeTableDetail(projectNumber, this.soilType)
      .pipe(takeUntil(this.cleanupService.unsubscribe))
      .subscribe((data: Array<MergeTable>) => {
        if (data) {
          console.log('res table', data);

          this.dataSource = data.map((elem: MergeTable) => {
            let el: any;

            if (this.soilType === 'nativesoil') {
              el = this.setSampleDataForDisplay(elem);
            } else {
              el = this.setSampleDataForPrecast(elem);
              el = {
                ...elem, 
              };
            }

            // Ensure that nativeSoilSamples is handled even if empty
            if (Array.isArray(el.nativeSoilSamples) && el.nativeSoilSamples.length === 0) {
              el.nativeSoilSamples = []; // If empty, set as an empty array
            }

            return el;
          });
        }
      });
  }

  onSaveMerge() {
    if (this.projectId && this.copyProjectId) {
      const selectedProjectId = this.selectedProjectIds[0];
      if (this.soilType === 'precast') {
        // Pass the first project ID (string), not the entire array
        this.ApiService.postMergePrecastDetail(this.selectedProjectIds, selectedProjectId).subscribe({
          next: (response) => {
            if (response) {
              const successMessage: IMessage = messagesConfig.mergeSubmitUserSuccessMessage;
              const url = '/dashboard';
              this.showUpdatePopUpMessage(successMessage, url);
            }
          },
          error: () => {},
        });
      } else {
        this.ApiService.postMergeNativeDetail(this.selectedProjectIds, selectedProjectId).subscribe({
          next: (response) => {
            if (response) {
              const successMessage: IMessage = messagesConfig.mergeSubmitUserSuccessMessage;
              const url = '/dashboard';
              this.showUpdatePopUpMessage(successMessage, url);
            }
          },
          error: () => {},
        });
      }
    }
  }

  /** Cancel */

  onCancel(): void {
    const routeTo = '/dashboard';

    this.pageLeaveDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'worksheet-dialog',
      data: {
        title: 'Cancel Changes',
        content: 'Are you sure you want to cancel? Your Changes will not be saved!',
        cancelText: 'NO, CONTINUE',
      },
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.pageLeaveDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        this.router.navigate([routeTo]);
      }
    });
  }

  setSampleDataForDisplay(elem: any): any {
    // Clone the element to avoid mutating the original data directly
    const el: any = {
      ...elem,
    };
    // Iterate through each native soil sample and assign displayDepth
    if (el.nativeSoilSamples && el.nativeSoilSamples.length > 0) {
      el.nativeSoilSamples = el.nativeSoilSamples.map((sample: any) => {
        // Handle moisture content logic for each sample
        if (sample.moistureOptimum) {
          sample.moistureContent = 'Optimum';
        } else if (sample.moistureAsReceived) {
          sample.moistureContent = 'In-Situ/As Received';
        } else {
          sample.moistureContent = sample.specifiedMoistureContent;
        }
        // Handle sampleDepth and sampleEndDepth logic
        if (sample.sampleDepth === '') {
          sample.sampleDepth = null;
        }
        // Construct displayDepth based on sampleDepth and sampleEndDepth
        if (sample.sampleEndDepth !== '') {
          sample.displayDepth = `${sample.sampleDepth}-${sample.sampleEndDepth}`;
        }
        // If sampleDepth is undefined and sampleEndDepth exists, mark as "Stockpile"
        if (sample.sampleDepth === undefined && sample.sampleEndDepth !== '') {
          sample.displayDepth = 'Stockpile';
        }

        sample.sampleTypeMasterId = sample.sampleTypeMasterId === '3ab1b1ca-7964-4a72-9818-202f0b3c592b' ? 'Bulk' : 'Tube';
        return sample;
      });
    }

    return el;
  }

  setSampleDataForPrecast(elem: any): any {
    const el: any = {
      ...elem,
    };
    // Iterate through each precast sample and assign the label based on the sampleTypeMasterId
    if (el.preCastSamples && el.preCastSamples.length > 0) {
      el.preCastSamples = el.preCastSamples.map((sample: any) => {
        // Find the corresponding sampleType from the precastSampleTypes array
        const matchedSampleType = this.precastSampleTypes.find((type: PrecastSampleType) => type.id === sample.sampleTypeMasterId);
  
        // If a match is found, assign the label
        if (matchedSampleType) {
          sample.sampleTypeMasterId = matchedSampleType.label;
        } else {
          // If no match is found, you can assign a default label or leave it empty
          sample.sampleTypeMasterId = 'Unknown Sample Type'; // Or any other default label
        }
  
        return sample;
      });
    }
  
    return el;
  }

  onProjectsTableSelected(selectedIds: string[]): void {
    // Update the component's state with the selected project IDs
    this.selectedProjectIds = selectedIds;
    this.updateSaveButtonState();
  }

  onProjectSelected(event: any): void {
    // Extract the selected project ID from the event object
    const selectedProjectId = event.value;
    // Update the component's state with the selected project ID
    this.selectedProjectId = selectedProjectId;
    // Find the selected project in the data source
    const selectedProject = this.dataSource.find((project) => project.id === selectedProjectId);
    // Update the copyProjectId with the selected project's ID or null if not found
    this.copyProjectId = selectedProject?.id ?? null;
    this.updateSaveButtonState();
  }

  showUpdatePopUpMessage(successMessage: IMessage, routeTo: string): void {
    this.updateSuccess = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.updateSuccess.afterClosed().subscribe(() => {
      if (routeTo) {
        this.sharedService.routeValue(routeTo);
      }
    });
  }

  updateSaveButtonState(): void {
    if (this.selectedProjectIds.length > 1 && this.copyProjectId) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }
  }
}
