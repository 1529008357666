<div class="gcontainer merge-container" #mergepage *ngIf="companyInformation && dataSource">
  <div class="r-header-wrapper">
    <!-- Breadcrumb-->
    <div class="no-print">
      <nav aria-label="breadcrumb" class="bredcrums">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active breadcrumb cursor" aria-current="page">
            <a class="c-primary" [routerLink]="'/dashboard'" tabindex="0"> Dashboard </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Merge Project - 
            <span *ngIf="soilType === 'precast'">Pre Cast</span>
            <span *ngIf="soilType === 'nativesoil'">Native Soil</span>
          </li>
        </ol>
      </nav>
    </div>
    <!-- Header Title-->
    <div class="row">
      <div class="col-sm-10">
        <h1 class="header-title">Merge Project</h1>
      </div>
    </div>

    <!-- Company Details -->
    <div class="company-wrapper">
      <app-company-info *ngIf="companyInformation" [data]="companyInformation"> </app-company-info>
    </div>

    <!-- Merge Tables-->

    <div class="table-wrapper">
      <app-merge-table
        [headerColumns]="headerColumns"
        [displayedColumns]="displayedColumns"
        [dataSource]="dataSource"
        [soiltype]="soilType"
        (projectChecked)="onProjectsTableSelected($event)">
      </app-merge-table>
    </div>

    <!-- Drop down project select-->
    <div class="col-md-4 project-dropdown">
      <mat-label class="mat-label" for="project">Select the primary project to which merge should be done</mat-label>
      <mat-form-field appearance="outline" class="mat-form-field-width">
        <mat-select placeholder="Select the Project" (selectionChange)="onProjectSelected($event)">
          <mat-option *ngFor="let project of dataSource" [value]="project.id">
            {{ project.projectNumber }} - {{ project.projectName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="spacer"></div>

    <div class="btn-wrapper">
      <div class="no-print btn-container btn-right">
        <button class="split-flag" mat-raised-button (click)="onCancel()">CANCEL</button>
        &nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="onSaveMerge()" [disabled]="!showBtn">SAVE</button>
      </div>
    </div>
  </div>
</div>
