/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { SampleStatus } from 'src/app/features/dashboard/components/shipment-received/shipment-received.component';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { Sample } from 'src/app/shared/models/worksheet-checkbox.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { PrecastSampleType, NativeSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-sample-information',
  templateUrl: './sample-information.component.html',
  styleUrls: ['./sample-information.component.scss'],
  providers: [DatePipe],
})
export class SampleInformationComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() dataSource: any[];
  @Input() headerColumns: any[];
  @Input() displayedColumns: any[];
  @Input() link: string;
  @Input() enableEditButton: boolean | null;
  @Input() returnUrl: string | null;
  @Input() data: ICompany;
  @Input() soilType: string;
  @Input() sampleViewInfo: Sample[];
  @Input() precastSampleTypes : PrecastSampleType [] = [];
  @Input() nativeSoilSampleTypes : NativeSampleType [] = [];
  @Output() samplesChange = new EventEmitter<Sample[]>();
  @Output() splitSamplesChange = new EventEmitter<Sample[]>();
  @Output() noCheckboxChecked = new EventEmitter<boolean>();
  @Output() checkStatusTypeValue = new EventEmitter<boolean>();
  sampleStatuses: SampleStatus[];
  samples: Sample[] = [];
  splitSamples: Sample[] = [];
  showHide = false;
  hideCheckbox = false;
  formGroup: FormGroup;
  userAccount: any;
  userRoles: string[] = [];
  isFlagged = false;
  isSplit = false;
  constructor(
    private precastService: PrecastService,
    private fb: FormBuilder,
    public userService: UserService,
    private cdr: ChangeDetectorRef,
  ) {}

  viewForm = false;
  isAnyChecked = false;
  hasReceived = false;
  ngOnInit(): void {
    this.showHide = this.data?.id === null;
    if (sessionStorage.getItem('submission-flagged')) {
      const flag = sessionStorage.getItem('submission-flagged');
      if (flag) {
        this.isFlagged = true;
      }
    }
    if (sessionStorage.getItem('split-submission')) {
      const split = sessionStorage.getItem('split-submission');
      if (split) {
        this.isSplit = true;
      }
    }
    if (this.data?.id) {
      this.sampleLookupStatus();
    }

    this.initFormGroup();
    this.checkIfAnyReceivedSample();
    if (this.soilType === 'nativesoil') {
      if (this.dataSource) {
        this.emitNativeSamples();
        if (this.isSplit) {
          this.emitSplitNativeSamples();
        }
      }
    } else if (this.dataSource) {
      this.emitPrecastSamples();
      if (this.isSplit) {
        this.emitSplitPrecastSamples();
      }
    }
    this.subscribeToFormGroupChanges();

    if (this.dataSource) {
      this.dataSource.forEach((element) => {
        if (element.sampleStatusTypeId === null) {
          this.hideCheckbox = true;
        } else {
          this.hideCheckbox = false;
        }
      });
    }
    const editViewSubmission = sessionStorage.getItem('editViewSubmission');
    if (this.data?.id !== null && editViewSubmission === 'true') {
      this.viewForm = true;
    } else if (editViewSubmission === 'true') {
      this.viewForm = true;
    } else {
      this.viewForm = false;
    }
    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
    this.checkMissingOrDamaged();
    this.checkReceivedSample();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sampleViewInfo'] && this.sampleViewInfo) {
      // Now sampleViewInfo is populated, you can proceed safely
      this.checkSampleViewInfoValidity();
    }
  }

  checkSampleViewInfoValidity() {
    if (Array.isArray(this.sampleViewInfo)) {
      console.log('sampleViewInfo is valid:', this.sampleViewInfo);
    } else {
      console.warn('sampleViewInfo is not properly initialized.');
    }
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  subscribeToFormGroupChanges() {
    this.formGroup.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(() => {
      if (this.soilType === 'nativesoil') {
        this.emitNativeSamples();
        if (this.isSplit) {
          this.emitSplitNativeSamples();
        }
      } else if (this.soilType === 'precast') {
        this.emitPrecastSamples();
        if (this.isSplit) {
          this.emitSplitPrecastSamples();
        }
      }
    });
  }

  // check formgroup value set(true or false) based on datasource

  initFormGroup() {
    const group: { [key: string]: FormControl } = {};
    if (this.dataSource && this.headerColumns) {
      this.dataSource.forEach((element) => {
        this.headerColumns.forEach((col) => {
          const controlName = this.getControlName(element, col.name);
          // group[controlName] = new FormControl(false);
          if (this.isSplit) {
            let isChecked = false;
            // Check if sampleStatusTypeId is "Missing" or "Damaged" and set checkbox to true
            if (
              element.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'
              || element.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932'
            ) {
              isChecked = true;
            }
            // Initialize the form control with the correct value
            group[controlName] = new FormControl(isChecked);
          } else {
            // group[controlName] = new FormControl(!!this.getInitialCheckboxValue(element, col.name));
            const initialCheckedValue = this.getInitialCheckboxValue(element, col.name);
            group[controlName] = new FormControl(initialCheckedValue);
          }
        });
      });
    }
    this.formGroup = this.fb.group(group);
  }

  getInitialCheckboxValue(element: any, columnName: string): boolean {
    const samples = this.sampleViewInfo?.find((sample) => sample.sampleIdName === element.sampleId);
    if (samples) {
      switch (columnName) {
        case 'sampleTypeMasterId':
          return !!samples.sampleType;
        case 'displayDepth':
          return !!samples.sampleDepth;
        case 'moistureContent':
          return !!samples.moistureOptimum;
        default:
          return !!samples[columnName];
      }
    }
    return false;
  }

  sampleLookupStatus() {
    this.precastService.getSampleStatusLookup().subscribe({
      next: (res: SampleStatus[]) => {
        if (res && res.length > 0) {
          this.sampleStatuses = res;
        }
      },
      error: (error) => {
        console.error('Error fetching sample statuses:', error);
      },
    });
  }

  getSampleTypeName(id: string): string {
    if (this.soilType === 'precast') {
      return this.getPrecastSampleTypeName(id);
    }
    return this.getNativeSampleTypeName(id);
  }

  getPrecastSampleTypeName(id: string): string {
    const item = this.precastSampleTypes.find((entry:PrecastSampleType) => entry.id === id);
    return item ? item.label : '';
  }

  getNativeSampleTypeName(id: string): string {
    const item = this.nativeSoilSampleTypes.find((entry:NativeSampleType) => entry.id === id);
    return item ? item.label : '';
  }

  getSampleStatusLabel(id: string): string {
    if (!id || !this.sampleStatuses) {
      return '';
    }

    const status = this.sampleStatuses.find((statusId) => statusId.id === id.toString());
    return status ? status.label : '';
  }

  // particular column set background color based on colname
  getCellClass(colName: string, element: any): string {
    if (colName === 'sampleStatusTypeId') {
      if (this.isSplit) {
        const checkboxStatus = this.formGroup.get(`${element.id}_sampleStatusTypeId`)?.value;
        if (checkboxStatus) {
          return 'split-background';
        }
      }
      // const statusLabel = this.getSampleStatusLabel(element[colName]);
      // if (['Damaged', 'Missing', 'Received'].includes(statusLabel)) {
      //   return 'row-white';
      // }
    }
    return '';
  }

  getCellColor(rowIndex: number, colName: string): string {
    // Check if sampleViewInfo is defined and if rowIndex is within bounds
    if (!Array.isArray(this.sampleViewInfo) || this.sampleViewInfo.length <= rowIndex) {
      // console.warn(`Invalid access to sampleViewInfo at rowIndex ${rowIndex}`);
      return '';
    }
    const dataElement = this.sampleViewInfo?.[rowIndex];
    const colNameMap: any = {
      sampleTypeMasterId: 'sampleType',
      displayDepth: 'sampleDepth',
      moistureContent: 'moistureOptimum',
    };
    // If the `isSplit` flag is true, use split logic
    if (this.isSplit) {
      if (dataElement[colName] === true || dataElement[colNameMap[colName]] === true) {
        return 'red-text';
      }
    } else {
      // Otherwise, use the `samples` array to check
      const sample = this.samples[rowIndex];
      if (sample && (sample[colName] === true || sample[colNameMap[colName]] === true)) {
        return 'red-text';
      }
    }
    return '';
  }

  shouldShowCheckbox() {
    return this.roleCheck(['Admin', 'Manager']) && this.isFlagged;
  }

  getControlName(element: any, colName: string): string {
    return `${element.id}_${colName}`;
  }

  getDisplayDepth(item: any): string {
    if (item.stockPile ) {
      return 'Stockpile';
    }
    if (item.displayDepth === `${null}-${null}`) {
      return '';
    }
    return item.displayDepth;
  }

  // background color set based on API response value samplestatusTypeId
  getRowClasses(row: any): Record<string, boolean> {
    const classes: Record<string, boolean> = {};
    if (this.isSplit) {
      const checkboxStatus = this.formGroup.get(`${row.id}_sampleStatusTypeId`)?.value;
      if (checkboxStatus) {
        classes['split-background'] = true;
      }
    } else {
      const statusClassMap: Record<string, string> = {
        'd471cab0-df24-4a80-a670-9994fbdf5f6f': 'received-row',
        '2320323b-65e5-4270-95ce-6fa221d3c0b5': 'missing-row',
        '33de1f0d-8209-4d52-a4c7-18e4918d9932': 'damaged-row',
      };
      const statusClass = statusClassMap[row.sampleStatusTypeId];
      if (statusClass) {
        classes[statusClass] = true;
      }
    }
    return classes;
  }
  // emit the native soil checkbox value

  emitNativeSamples() {
    this.samples = [];
    this.dataSource.forEach((element) => {
      const sample: Sample = {
        id: element.id || '',
        sampleIdName: element.sampleId || '',
        sampleColDate: element.collectionDate || null,
        sampleTypeData: element.sampletype || '',
        sampleStatusTypeId: element.sampleStatusTypeId || '',
        stockPile: element.stockPile || false,
        sampleDepthData:
          element.sampleDepth && element.sampleEndDepth ? `${element.sampleDepth}-${element.sampleEndDepth}` : '',
        sampleId: !!this.formGroup.get(`${element.id}_sampleId`)?.value || false,
        sampleType: !!this.formGroup.get(`${element.id}_sampleTypeMasterId`)?.value || false,
        sampleDepth: !!this.formGroup.get(`${element.id}_displayDepth`)?.value || false,
        collectionDate: !!this.formGroup.get(`${element.id}_collectionDate`)?.value || false,
        soilDescription: !!this.formGroup.get(`${element.id}_soilDescription`)?.value || false,
        maxDryDensity: !!this.formGroup.get(`${element.id}_maxDryDensity`)?.value || false,
        optimumMoistureContent: !!this.formGroup.get(`${element.id}_optimumMoistureContent`)?.value || false,
        compactionEffort: !!this.formGroup.get(`${element.id}_compactionEffort`)?.value || false,
        moistureOptimum: !!this.formGroup.get(`${element.id}_moistureContent`)?.value || false,
      };

      this.samples.push(sample);
    });
    this.samplesChange.emit(this.samples);
  }

  // emit the precast soil checkbox value
  emitPrecastSamples() {
    this.samples = [];
    this.dataSource.forEach((element) => {
      const sample: Sample = {
        id: element.id || '',
        sampleIdName: element.sampleId || '',
        sampleColDate: element.sampleCollectionDate || null,
        sampleTypeData: element.sampleType || '',
        sampleStatusTypeId: element.sampleStatusTypeId || '',
        sampleId: !!this.formGroup.get(`${element.id}_sampleId`)?.value || false,
        sampleType: !!this.formGroup.get(`${element.id}_sampleTypeMasterId`)?.value || false,
        sampleCollectionDate: !!this.formGroup.get(`${element.id}_sampleCollectionDate`)?.value || false,
        mixDesignCode: !!this.formGroup.get(`${element.id}_mixDesignCode`)?.value || false,
        numSamplesPerSet: !!this.formGroup.get(`${element.id}_numSamplesPerSet`)?.value || false,
        truckNumber: !!this.formGroup.get(`${element.id}_truckNumber`)?.value || false,
        ticketNumber: !!this.formGroup.get(`${element.id}_ticketNumber`)?.value || false,
      };
      this.samples.push(sample);
    });
    this.samplesChange.emit(this.samples);
  }

  emitSplitNativeSamples() {
    this.splitSamples = []; // Reset the array

    // Process checked rows only
    this.dataSource.forEach((element) => {
      const sampleStatusChecked = this.formGroup.get(`${element.id}_sampleStatusTypeId`)?.value;

      if (sampleStatusChecked) {
        const splitSample: Sample = {
          id: element.id || '',
          proctorType: element.proctorType || null,
          sampleId: element.sampleId || '',
          collectionDate: element.collectionDate || null,
          sampleDepth: element.sampleDepth || null,
          sampleEndDepth: element.sampleEndDepth || null,
          stockPile: element.stockPile || false,
          sampleType: element.sampleTypeMasterId || '',
          soilDescription: element.soilDescription || '',
          maxDryDensity: element.maxDryDensity || null,
          compactionEffort: element.compactionEffort || '',
          moistureOptimum: element.moistureOptimum || false,
          moistureAsReceived: element.moistureAsReceived || false,
          specifiedMoistureContent: element.specifiedMoistureContent || null,
          optimumMoistureContent: element.optimumMoistureContent || null,
          sampleStatusTypeId: element.sampleStatusTypeId || '',
          sampleStatusType: element.sampleStatusType,
          weightReceived: element.weightReceived || null,
          weightUsed: element.weightUsed || null,
          weightRemaining: element.weightRemaining || null,
          sampleTypeMasterId: element.sampleTypeMasterId || null,
        };

        this.splitSamples.push(splitSample);
      }
    });

    // Emit only the checked samples
    this.splitSamplesChange.emit(this.splitSamples);
  }

  emitSplitPrecastSamples() {
    this.splitSamples = [];
    this.dataSource.forEach((element) => {
      const sampleStatusChecked = this.formGroup.get(`${element.id}_sampleStatusTypeId`)?.value;
      if (sampleStatusChecked) {
        const splitSample: Sample = {
          id: element.id || '',
          sampleId: element.sampleId || '',
          sampleCollectionDate: element.sampleCollectionDate || null,
          sampleType: element.sampleTypeMasterId || '',
          mixDesignCode: element.mixDesignCode || '',
          numSamplesPerSet: element.numSamplesPerSet || null,
          thermalSpecifications: element.thermalSpecifications || '',
          truckNumber: element.truckNumber || '',
          ticketNumber: element.ticketNumber || '',
          sampleStatusTypeId: element.sampleStatusTypeId || '',
          sampleStatusType: element.sampleStatusType,
          sampleTypeMasterId: element.sampleTypeMasterId || null,
        };

        this.splitSamples.push(splitSample);
      }
    });
    this.splitSamplesChange.emit(this.splitSamples);
  }

  onCheckboxChange(element: any) {
    const checkboxStatus = this.formGroup.get(`${element.id}_sampleStatusTypeId`)?.value;
    element.isChecked = checkboxStatus;
    this.emitSplitNativeSamples();
    if (this.hasReceived) {
      this.checkIfAnyCheckboxChecked();
    }
  }

  checkIfAnyReceivedSample(): void {
    this.hasReceived = false;
    this.dataSource.forEach((element) => {
      if (element.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f') {
        this.hasReceived = true;
      }
    });
    this.noCheckboxChecked.emit(this.hasReceived);
    if (this.hasReceived) {
      this.checkIfAnyCheckboxChecked();
    }
  }

  checkIfAnyCheckboxChecked(): boolean {
    this.isAnyChecked = false;
    let atLeastOneChecked = false;
    let selectedCount = 0;
    this.dataSource.forEach((element) => {
      const checkboxStatus = this.formGroup.get(`${element.id}_sampleStatusTypeId`)?.value;

      if (checkboxStatus) {
        selectedCount++;
        atLeastOneChecked = true;
      }
    });

    // console.log(`Total records: ${this.dataSource.length}`);
    // console.log(`Selected records: ${selectedCount}`);

    const allUnchecked = selectedCount === 0;
    const allChecked = selectedCount === this.dataSource.length;
    const emitValue = !(allUnchecked || allChecked);
    this.noCheckboxChecked.emit(emitValue);
    this.isAnyChecked = atLeastOneChecked;
    return this.isAnyChecked;
  }

  // Function to check if any sample is "Missing" or "Damaged"
  checkMissingOrDamaged() {
    let hasMissingOrDamaged = false;
    this.dataSource.forEach((element) => {
      const sampleStatus = element.sampleStatusTypeId; // Accessing the sampleStatusTypeId
      // Check if the sample is "Missing" or "Damaged"
      if (sampleStatus === '2320323b-65e5-4270-95ce-6fa221d3c0b5' || sampleStatus === '33de1f0d-8209-4d52-a4c7-18e4918d9932') {
        hasMissingOrDamaged = true;
      }
    });
    // If any sample is "Missing" or "Damaged", emit false
    if (hasMissingOrDamaged) {
      this.checkStatusTypeValue.emit(false);
    }
  }

  // Function to check if any sample is "Received"
  checkReceivedSample() {
    let hasReceived = false;
    this.dataSource.forEach((element) => {
      const sampleStatus = element.sampleStatusTypeId;
      // Check if the sample is "Received"
      if (sampleStatus === 'd471cab0-df24-4a80-a670-9994fbdf5f6f') {
        hasReceived = true;
      }
    });
    // If any sample is "Received", emit true
    if (hasReceived) {
      this.checkStatusTypeValue.emit(true);
    }
  }
}
